import React from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

export interface ISurvivorStatusProps {
  save: () => void;
}

const useStyles = makeStyles((theme) => ({
  statusBar: {
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(8),
    },
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(8.7),
    },
    backgroundColor: theme.palette.primary.light,
  },
  statusBarBadge: {
    backgroundColor: theme.palette.secondary.light,
  },
  saveButton: {
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.contrastText),
    backgroundColor: theme.palette.primary.dark,
  },
}));

function SurvivorPlayerStatusBar(props: ISurvivorStatusProps) {
  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.statusBar}>
      <Toolbar>
        <Grid container spacing={1} justify="center">
          <Grid item md={1} className={classes.saveButton}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SaveIcon />}
              onClick={props.save}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(SurvivorPlayerStatusBar);
