import React, { useState, useEffect } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ILeague } from "../../../../api/LeagueAPI";
import { reducers } from "../../../../reducers";
import { useSelector } from "react-redux";
import { map, get } from "lodash-es";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Transition from "./Transition";
import TextField from "@material-ui/core/TextField";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    mainGrid: {
      marginTop: theme.spacing(3),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    textField: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      color: "white",
      paddingBottom: 0,
      marginTop: 0,
      fontWeight: 500,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface IManageLeagueDialogProps {
  league: ILeague;
  open: boolean;
  handleClose: () => void;
  handleSubmit: (
    password: any,
    finalPassword: any,
    leagueName: any,
    tournament: any,
    type: any
  ) => void;
  passordError: boolean;
  passordNullError: boolean;
}

export default function Leagjoin(props: IManageLeagueDialogProps) {
  const {
    league: { tournament, leagueName, type }, 
  } = props;
  const [password, setPassword] = useState(([] as unknown) as string);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [, setMembers] = useState([] as string[]);
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [finalPassword, setFinalPassord] = useState(([] as unknown) as string);
  const userPassword = props.league.password;

  useEffect(() => {
    return function cleanup() {
      setMembers([]);
    };
  }, []);

  // store watcher
  useEffect(() => {
    const updateMembers = async () => {
      const response = await store.members;
      setMembers(map(get(response, "result.Items", {}), "userId"));
    };
    updateMembers();
    setFinalPassord(userPassword);
  }, [store.members, userPassword]);

  return (
    <React.Fragment>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          Join Leagues - {props.league.leagueName}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            You can Join people to play in this League. Just enter their
            referral code below.
          </Typography>
          <TextField
            id="confirmPassword"
            type="test"
            placeholder="Enter your referral code"
            label="Enter your referral code"
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            autoFocus
          />

          {props.passordNullError === true ? (
            <div style={{ color: "red" }}>Referral code is required</div>
          ) : (
            ""
          )}
          {props.passordError === true ? (
            <div style={{ color: "red" }}>Referral code not match</div>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={() =>
              props.handleSubmit(
                password,
                finalPassword,
                leagueName,
                tournament,
                type
              )
            }
            color="primary"
            variant="outlined"
          >
            Join League
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
