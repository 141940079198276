import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Transition from "./Transition";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import { LEAGUE_ACTIONS } from "../../../../reducers/LeagueReducer";
import { reducers } from "../../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, map, sortBy, reverse, get } from "lodash-es";
// import { ILeague } from "../../../../api/LeagueAPI";
import FormHelperText from '@material-ui/core/FormHelperText';

interface ICreateDialogProps<T> {
  open: boolean;
  formFields: T;
  helperTexts: { [key: string]: string };
  setFormField: React.Dispatch<React.SetStateAction<T>>;
  fields: Array<{
    id: string;
    label: string;
  }>;
  handleClose: () => void;
  handleSubmit: (tournament: string, leagueType: string) => void;
  tournamnetNullError: boolean;
  leagueTypeNullError: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radio: {
      "&$checked": {
        color: "#66bb6a",
      },
    },
    redioStyle: {
      paddingTop: 10,
    },
    buttonJustify: {
      justifyContent: "space-between",
    },
    formControl: {
      paddingTop: 10,
    },
    checked: {},
  })
);

export default function CreateLeagueDialog<T>(props: ICreateDialogProps<T>) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [leagueType, setLeagueType] = useState("");
  const [tournament, setTournament] = useState("");
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [allTournament, setAllTournament] = useState([] as any);
  const dispatch = useDispatch();
  ///////////
  const [leagueTypeValue , setLeagueTypeValue] = useState('both');

  const selectTournament = (event: React.ChangeEvent<{ value: unknown }>) => {
    // console.log(event.target.value);
    setTournament(event.target.value as string);
  };

  //setFormField

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLeagueType(event.target.value);

    props.setFormField({
      ...props.formFields,
      leagueType: event.target.value,
    });
  };

  useEffect(() => {
    const getAllTournament = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_TOURNAMENT });
    };
    getAllTournament();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  // store watcher
  useEffect(() => {
    const updateAllTournament = async () => {
      const tournaments = await store.all_tournament;
      const sortedAllTournament = reverse(
        sortBy(get(tournaments, "result.Items"), (l) => l.created || "")
      );
      setAllTournament(sortedAllTournament);
    };
    updateAllTournament();
  }, [store.all_tournament]);

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        fullScreen={fullScreen}
      >
        <DialogTitle id="form-dialog-title">Create a League</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            To Create a new private league, please enter the following
            information.
          </DialogContentText>
          {map(props.fields, ({ id, label }, index) => (
            <TextField
              key={id}
              autoFocus={index === 0}
              required={index === 0}
              margin="dense"
              id={id}
              label={label}
              onChange={(e) =>
                props.setFormField({
                  ...props.formFields,
                  [id]: e.target.value,
                })
              }
              helperText={props.helperTexts[id]}
              error={!isEmpty(props.helperTexts[id])}
              fullWidth
            />
          ))}
          <Grid item container xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Tournament
              </InputLabel>
              <Select
                value={tournament}
                onChange={selectTournament}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {map(allTournament, (tournament) => {
                  // console.log(tournament)
                  delete tournament.schedule;
                  // console.log(tournament)
                  if (tournament.tournamentStatus !== "Completed") {
                    return (
                      <MenuItem
                        value={tournament.tournament}
                        key={tournament.tournament}
                        onClick={()=>{setLeagueType((tournament.type).toLowerCase());  setLeagueTypeValue((tournament.type).toLowerCase())}}
                      >
                        {`${tournament.tournament}`} 
                        <FormHelperText style={{paddingLeft: 5}}>({tournament.type} type) (Status - {tournament.tournamentStatus})</FormHelperText>
                      </MenuItem>
                    )

                  }

                }
                )
                }
              </Select>
              {props.tournamnetNullError === true ? (
                <div style={{ color: "red" }}>Tournament is required</div>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item container xs={12}>
            <FormControl className={classes.redioStyle}>
              <FormLabel>Select League Type</FormLabel>
              <RadioGroup
                row
                aria-label="leagueType"
                name="leagueType"
                id={leagueType}
                value={leagueType}
                onChange={handleChange}
                defaultValue="both"
              >
                <FormControlLabel
                  value="both"
                  id="both"
                  name="both"
                  disabled={leagueTypeValue !== "both"}
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="Both League"
                  checked={leagueType === "both"}
                />
                <FormControlLabel
                  value="team"
                  id="team"
                  name="team"
                  control={<Radio color="primary" />}
                  label="Team League"
                  disabled={ (leagueTypeValue !== "both" && leagueTypeValue !== "team" )}
                  checked={leagueType === "team"}
                />
                <FormControlLabel
                  value="player"
                  id="player"
                  name="player"
                  disabled={ (leagueTypeValue !== "both" && leagueTypeValue !== "player" )}
                  control={<Radio color="primary" />}
                  label="Player League"
                  checked={leagueType === "player"}
                />
              </RadioGroup>
              {props.leagueTypeNullError === true ? (
                <div style={{ color: "red" }}>League Type required</div>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

        </DialogContent>
        <DialogActions className={classes.buttonJustify}>
          <Button
            variant="outlined"
            onClick={props.handleClose}
            color="default"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => props.handleSubmit(tournament, leagueType)}
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
