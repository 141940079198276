import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { CircularProgress, Grid } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import JumboButton from "./../dashboard/modules/components/JumboButton";
import Box from '@material-ui/core/Box';
import { isEmpty, map } from "lodash-es";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
}));
// ,Live:any,Upcoming:any
export default function FullWidthTabs({ Data, navigateToLeague }: any) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };



  const FilterData = (allTournament: any, type: string) => {

    return (<>
      {!isEmpty(allTournament) ? (
        <Grid>
          {map(allTournament, (tournament) => (
            <>
              {tournament.tournamentStatus === type ?
                <Grid
                  container
                  style={{ marginTop: 10 }}
                  alignItems="center"
                  justify="center"
                  key={tournament.tournament}
                >
                  <JumboButton
                    title={tournament.tournament}
                    description=""
                    image="https://source.unsplash.com/ghxL3qOfkPo"
                    imgText="main image description"
                    // onClick={() => naviPage(
                    //   tournament.tournament
                    // )}

                    onClick={() =>
                      // {setSelectedLeague(tournament);
                      // dispatch({
                      //   type: LEAGUE_ACTIONS.GET_LEAGUE_MEMBERS,
                      //   tournament: tournament.tournament,
                      // });
                      navigateToLeague(
                        tournament.tournament
                      )}
                  // }
                  />
                </Grid>
                : null}
            </>
          ))}

        </Grid>
      ) :
      <CircularProgress color="primary" />
      }
    </>)


  }

  return (

    <div className={classes.root}>
      {console.log(Data)}
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
          // className={classes.}
          centered
        // aria-label="full width tabs example"
        >
          <Tab label="Upcoming" {...a11yProps(0)} />
          <Tab label="Live" {...a11yProps(1)} />
          {/* <Tab label="Completed" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {FilterData(Data, 'Upcoming')}
          {/* {Completed} */}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {/* <Live/> */}
          {FilterData(Data, 'Live')}
          {/* 'ok' */}
        </TabPanel>
        {/* <TabPanel value={value} index={2} dir={theme.direction}>
          {FilterData(Data,'Completed')}
          {/* 'ok' */}
        {/* <Upcoming/> */}
        {/* </TabPanel> */}
      </SwipeableViews>
    </div>
  );
}
