import { cloneDeep } from "lodash-es";
import LeagueAPI, { IUserLeagues, IUserLeagueMembers } from "../api/LeagueAPI";
import DashboardAPI, { IGameScheduleResult, ITeamScore, IPlayerLeaguesResult, IPlayerLeaguesAsUserResult } from "../api/DashboardAPI";

export const LEAGUE_ACTIONS = {
  CREATE_LEAGUE: "CREATE_LEAGUE",
  GET_USER_LEAGUES: "GET_USER_LEAGUES",
  GET_ALL_LEAGUES: "GET_ALL_LEAGUES",
  GET_ALL_TOURNAMENT: "GET_ALL_TOURNAMENT",
  GET_ALL_TOURNAMENT_ID: "GET_ALL_TOURNAMENT_ID",
  GET_LEAGUE_MEMBERS: "GET_LEAGUE_MEMBERS",
  GET_SCHEDULE: "GET_SCHEDULE",
  GET_SURVIVOR_PREDICTION: "GET_SURVIVOR_PREDICTION",
  SET_SURVIVOR_PREDICTION: "SET_SURVIVOR_PREDICTION",
  GET_SURVIVOR_PLAYER_PREDICTION: "GET_SURVIVOR_PLAYER_PREDICTION",
  GET_SURVIVOR_PLAYER_PREDICTION_FOR_MATCH: "GET_SURVIVOR_PLAYER_PREDICTION_FOR_MATCH",
  SET_SURVIVOR_PLAYER_PREDICTION: "SET_SURVIVOR_PLAYER_PREDICTION",
  GET_TEAM_SCORE_BOARD:"GET_TEAM_SCORE_BOARD",
  GET_USER_LEAGUE_BY_LEAGE_TYPE: "GET_USER_LEAGUE_BY_LEAGE_TYPE",
  GET_LEAGUE_BY_LEAGE_TYPE : "GET_LEAGUE_BY_LEAGE_TYPE",
  USER_ALL_TOURNAMENT_POINTS : "USER_ALL_TOURNAMENT_POINTS",
  RESET: "RESET",
};

export interface ILeagueState {
  // eslint-disable-next-line camelcase
  user_leagues: IUserLeagues;
  all_leagues: IUserLeagues;
  all_tournament: Promise<IUserLeagueMembers>;
  members: Promise<IUserLeagueMembers>;
  schedule: Promise<IGameScheduleResult>;
  players_score: ITeamScore;
  all_Player_User_League_By_League_type: Promise<IPlayerLeaguesResult>;
  get_League_By_Leage_Type: Promise<IPlayerLeaguesAsUserResult>;
  [key: string]: any;
}

const initialState: ILeagueState = {
  user_leagues: {} as IUserLeagues,
  all_leagues: {} as IUserLeagues,
  all_tournament: {} as Promise<IUserLeagueMembers>,
  players_score: [] as unknown as ITeamScore,
  members: {} as Promise<IUserLeagueMembers>,
  schedule: {} as Promise<IGameScheduleResult>,
  all_Player_User_League_By_League_type: {} as Promise<IPlayerLeaguesResult>,
  get_League_By_Leage_Type: {} as Promise<IPlayerLeaguesAsUserResult>
};

export default (state: ILeagueState = initialState, action: any) => {
  switch (action.type) {
    case LEAGUE_ACTIONS.GET_USER_LEAGUES: {
      return {
        ...state,
        user_leagues: LeagueAPI.getUserLeagues(),
      };
    }

    case LEAGUE_ACTIONS.GET_ALL_LEAGUES: {
      return {
        ...state,
        all_leagues: LeagueAPI.getAllLeague(action.tournament),
      };
    }

    case LEAGUE_ACTIONS.GET_ALL_TOURNAMENT: {
      return {
        ...state,
        all_tournament: LeagueAPI.getAllTournament(),
      };
    }
    case LEAGUE_ACTIONS.GET_ALL_TOURNAMENT_ID: {
      return {
        ...state,
        all_tournament: LeagueAPI.getAllTournamentID(action.tournament),
      };
    }

    case LEAGUE_ACTIONS.GET_LEAGUE_MEMBERS: {
          return {
            ...state,
            members: LeagueAPI.getLeagueMembers(
              action.tournament,
              action.leagueName
            ),
          };
        }

    case LEAGUE_ACTIONS.GET_SCHEDULE: {
          return {
            ...state,
            schedule: DashboardAPI.getSchedule(action.tournament),
          };
        }

    case LEAGUE_ACTIONS.GET_SURVIVOR_PREDICTION: {
      return {
        ...state,
        survivorPrediction: LeagueAPI.getSurvivorPrediction(
          action.tournament,
          action.leagueName
        ),
      };
    }

    case LEAGUE_ACTIONS.SET_SURVIVOR_PREDICTION: {
      return {
        ...state,
        survivorPrediction: LeagueAPI.setSurvivorPrediction(action.payload),
      };
    }
    
    case LEAGUE_ACTIONS.GET_SURVIVOR_PLAYER_PREDICTION: {
      return {
        ...state,
        survivorPlayerPrediction: LeagueAPI.getSurvivorPlayerPrediction(
          action.tournament,
          action.leagueName,
          action.userName
        ),
      };
    }

    case LEAGUE_ACTIONS.GET_SURVIVOR_PLAYER_PREDICTION_FOR_MATCH: {
      return {
        ...state,
        survivorPlayerPrediction: LeagueAPI.getSurvivorPlayerPredictionForMatch(
          action.tournament,
          action.leagueName
        ),
      };
    }
    
    case LEAGUE_ACTIONS.SET_SURVIVOR_PLAYER_PREDICTION: {
      return {
        ...state,
        survivorPlayerPrediction: LeagueAPI.setSurvivorPlayerPrediction(action.payload),
      };
    }
    
    case LEAGUE_ACTIONS.GET_TEAM_SCORE_BOARD: {
      return {
        ...state,
        players_score: DashboardAPI.getTeamScoreBoard(action.tournament, action.match_no),
      };
    }

    case LEAGUE_ACTIONS.GET_LEAGUE_BY_LEAGE_TYPE: {
      return {
        ...state,
        get_League_By_Leage_Type: LeagueAPI.getLeagueByLeageType(action.tournament, action.leagueName, action.leagueType),
      };
    }

    case LEAGUE_ACTIONS.USER_ALL_TOURNAMENT_POINTS: {
      return {
        ...state,
        user_all_tournament_points: DashboardAPI.userAllTournamnetPoints(action.tournament, action.leagueName, action.userId),
      };
    }

    case LEAGUE_ACTIONS.RESET:
    default:
      return cloneDeep(initialState);
  }
};
