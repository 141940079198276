import React, { useMemo, useState, useEffect } from "react";
import { MatchStat } from "../../../../api/DashboardAPI";

import {
  Card,
  CardContent,
  Typography,
  CardActions,
  CardHeader,
  useTheme,
  Theme,
  makeStyles,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

interface IMatchStatsProps {
  stats: MatchStat;
  idx: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    padding: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
    padding: theme.spacing(4),
    width: "74px",
    height: "74px",
  },
  styleObj: {
    fontSize: 28,
    color: "#FFFFFF",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    fontSize: 18,
    backgroundColor: "#eeeeee",
    color: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardpaper: {
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "#1a90ff",
    fontSize: 18,
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  paperCon: {
    padding: theme.spacing(2),
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
    color: "#000000",
    backgroundColor: "#eeeeee",
    display: "flex",
    borderRadius: "50%",
    width: "100%",
    height: 210,
    "@media(max-Width: 1024px)": {
      height: 180,
      fontSize: 18,
    },
    "@media(max-Width: 768px)": {
      height: 120,
      fontSize: 16,
    },
    "@media(max-Width: 425px)": {
      height: 120,
      fontSize: 14,
    },
    "@media(max-Width: 375px)": {
      height: 100,
      fontSize: 13,
    },
    "@media(max-Width: 320px)": {
      height: 80,
      fontSize: 12,
    },
  },
  cardpaperCon: {
    padding: theme.spacing(2),
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
    backgroundColor: "#1a90ff",
    color: "#FFFFFF",
    display: "flex",
    width: "100%",
    height: 210,
    borderRadius: "50%",
    "@media(max-Width: 1024px)": {
      height: 180,
      fontSize: 18,
    },
    "@media(max-Width: 768px)": {
      height: 120,
      fontSize: 16,
    },
    "@media(max-Width: 425px)": {
      height: 120,
      fontSize: 14,
    },
    "@media(max-Width: 375px)": {
      height: 100,
      fontSize: 13,
    },
    "@media(max-Width: 320px)": {
      height: 80,
      fontSize: 12,
    },
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 45,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
    },
  })
)(LinearProgress);

const getSurvivorData = (stats: MatchStat, theme: Theme) => {
  const { left, right, leftCount = 0, rightCount = 0 } = stats;

  return [
    {
      title: left,
      value:
        leftCount === 0
          ? rightCount === 0
            ? 0.1
            : rightCount / 10
          : leftCount,
      color: theme.palette.primary.main,
    },
    {
      title: right,
      value:
        rightCount === 0
          ? leftCount === 0
            ? 0.1
            : leftCount / 10
          : rightCount,
      color: theme.palette.secondary.main,
    },
  ];
};

const getConfidenceData = (stats: MatchStat, theme: Theme) => {
  const { left, right, leftConfidence = 0, rightConfidence = 0 } = stats;

  return [
    {
      title: left,
      value: leftConfidence === 0 ? 5 : leftConfidence,
      color: theme.palette.primary.main,
    },
    {
      title: right,
      value: rightConfidence === 0 ? 5 : rightConfidence,
      color: theme.palette.secondary.main,
    },
  ];
};

export default function MatchStats({ stats, idx }: IMatchStatsProps) {
  const theme = useTheme();
  const classes = useStyles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => getSurvivorData(stats, theme), [stats]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => getConfidenceData(stats, theme), [stats]);

  const [leftCountPer, setLeftCountPer] = useState(Number);
  const [rightCountPer, setRightCountPer] = useState(Number);
  const totalValue = () => {
    var totalCount = stats.leftCount + stats.rightCount;
    setLeftCountPer((stats.leftCount * 100) / totalCount);
    setRightCountPer((stats.rightCount * 100) / totalCount);
  };

  useEffect(() => {
    totalValue();
  });

  const [leftConfidencePer, setLeftConfidencePer] = useState(Number);
  const [rightConfidencePer, setRightConfidencePer] = useState(Number);

  useEffect(() => {
    totalConfidenceValue();
  });

  const totalConfidenceValue = () => {
    setLeftConfidencePer(stats.leftConfidence / stats.leftCount);
    setRightConfidencePer(stats.rightConfidence / stats.rightCount);
  };

  return (
    <Card>
      <CardHeader title={`Match #${idx + 1}`} />
      <CardContent>
        <Typography variant="subtitle1" component="p">
          Survivor selections
        </Typography>
        <Grid
          container
          spacing={3}
          justify="center"
          className={classes.mainGrid}
        >
          <Grid item xs={6} sm={3}>
            <Paper className={classes.cardpaper}>{stats.left}</Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paper}>{stats.right}</Paper>
          </Grid>
        </Grid>
        <Grid className={classes.mainGrid}>
          <Box display="flex" alignItems="center">
            <Box minWidth={50}>
              <Typography variant="body2" color="textSecondary">
                {leftCountPer >= 0 ? leftCountPer.toFixed() : 0} %
              </Typography>
            </Box>
            <Box width="100%" mr={1}>
              <BorderLinearProgress
                variant="determinate"
                value={leftCountPer}
              />
            </Box>
            <Box minWidth={50}>
              <Typography variant="body2" color="textSecondary">
                {rightCountPer >= 0 ? rightCountPer.toFixed() : 0} %
              </Typography>
            </Box>
          </Box>
        </Grid>
        <br />
        <Typography variant="subtitle1" component="p">
          Confidence selections
        </Typography>
        <Grid
          container
          spacing={3}
          justify="center"
          className={classes.mainGrid}
        >
          <Grid item xs={6} sm={3}>
            <Paper color="primary" className={classes.cardpaperCon}>
              {stats.leftConfidence}
              <br />
              Avg - {leftConfidencePer >= 0 ? leftConfidencePer.toFixed() : 0}
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className={classes.paperCon}>
              {stats.rightConfidence} <br />
              Avg - {rightConfidencePer >= 0 ? rightConfidencePer.toFixed() : 0}
            </Paper>
          </Grid>
        </Grid>
        <br />
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}
