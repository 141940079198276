import React, { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { URL } from "../../Routes";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { LEAGUE_ACTIONS } from "../../reducers/LeagueReducer";
import { reducers } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, map, sortBy, reverse, get } from "lodash-es";
import { ILeague } from "../../api/LeagueAPI";
import Container from "@material-ui/core/Container";
import CardHeader from "@material-ui/core/CardHeader";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TournamentEditDialog from "../dashboard/modules/views/TournamentEditDialog";

interface IInsightProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  admin: boolean;
  history: any;
  setShowLoader: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: "flex",
    },
    cardDetails: {
      flex: 1,
    },
    cardMedia: {
      width: 160,
    },
    titleColor: {
      color: theme.palette.primary.dark,
    },
    root: {
      maxWidth: 345,
    },
    mainGrid: {
      marginTop: theme.spacing(3),
    },
    buttonJustify: {
      justifyContent: "space-between",
    },
    leftText: {
      textAlign: "left",
    },
  })
);

export default function TournamentEdit(props: IInsightProps) {
  if (!props.isAuthenticated) props.history.push(URL.HOME);
  if (!props.admin) props.history.push(URL.LEAGUES.HOME);
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [allTournament, setAllTournament] = useState([] as ILeague[]);
  const [isCreateOpen, openCreate] = useState(false);

  const [selectedLeague, setSelectedLeague] = useState({} as ILeague);

  useEffect(() => {
    const getAllTournament = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_TOURNAMENT });
    };
    getAllTournament();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  // store watcher
  useEffect(() => {
    const updateAllTournament = async () => {
      const tournaments = await store.all_tournament;
      const sortedAllTournamnet = reverse(
        sortBy(get(tournaments, "result.Items"), (l) => l.created || "")
      );
      setAllTournament(sortedAllTournamnet);
    };
    updateAllTournament();
  }, [store.all_tournament]);

  const naviTeamBoard = (tournament: string) => {
    const url = URL.LEAGUES.MANAGETOURNAMENTMATCH.replace(":game", tournament);
    history.push(url);
  };

  const naviPlayerBoard = (tournament: string) => {
    const url = URL.LEAGUES.SCHEDULE_EDITOR_PLAYER.replace(":game", tournament);
    history.push(url);
  };

  return (
    <>
      <Container maxWidth="lg" className={classes.mainGrid}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {!isEmpty(allTournament) ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {map(allTournament, (tournament) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={tournament.tournament}
                    >
                      <Card className={classes.root}>
                        <CardMedia
                          component="img"
                          alt="Contemplative Reptile"
                          height="140"
                          image="https://source.unsplash.com/ghxL3qOfkPo"
                          title="Contemplative Reptile"
                        />
                        <CardHeader
                          title={
                            <Typography
                              variant="h5"
                              component="h2"
                              className={classes.leftText}
                            >
                              {" "}
                              {tournament.tournament}{" "}
                            </Typography>
                          }
                          action={
                            <IconButton
                              aria-label="settings"
                              onClick={() => {
                                setSelectedLeague(tournament);
                                openCreate(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          }
                        />
                        <CardActions className={classes.buttonJustify}>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => naviTeamBoard(tournament.tournament)}
                          >
                            Team Board
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            color="default"
                            onClick={() =>
                              naviPlayerBoard(tournament.tournament)
                            }
                          >
                            Player Board
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) :
              <CircularProgress color="primary" />
            }

            <TournamentEditDialog
              open={isCreateOpen}
              handleClose={() => openCreate(false)}
              tournament={selectedLeague}
              setShowLoader={props.setShowLoader}

            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
