import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { CircularProgress, Fab, Paper } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import { cloneDeep, isEmpty, map, set } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { reducers } from "../../reducers";
import { LEAGUE_ACTIONS } from "../../reducers/LeagueReducer";
import { URL } from "../../Routes";
import Intro from "./modules/components/Intro";
import MatchEditor from "./modules/components/MatchEditor";
import Title from "./modules/components/Title";
import SurvivorStatusBar from "./modules/components/SurvivorStatusBar";
import DashboardAPI, { IMatch } from "../../api/DashboardAPI";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(13),
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: 0,
    },
  },
  leaguesSection: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  saveFab: {
    margin: 0,
    top: "auto",
    right: theme.spacing(35),
    bottom: theme.spacing(5),
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
    left: "auto",
    position: "fixed",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  table: {
    minWidth: 650,
  },
  leagueTitle: {
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.dark + " !important",
  },
  tabTitle: {
    backgroundColor: "#ffffff",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "90.00%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionStyle: {
    padding: "1px 0px",
  },
  tabSelected: {
    color: theme.palette.primary.dark + " !important",
  },
  scoreBoard : {
    margin: "-20px"
  }
}));

interface IScheduleEditorProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  admin: boolean;
  toggleSidebar: () => void;
  history: any;
  match: {
    params: { [key: string]: string };
  };
  setShowLoader:any;
}

export default function ManageTournamentMatch(props: IScheduleEditorProps) {
  if (!props.isAuthenticated || !props.admin) props.history.push(URL.HOME);
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUrl = window.location.pathname;
  const currentUrl22 = currentUrl.split("/");
  const tournament = currentUrl22[3];
  const { enqueueSnackbar } = useSnackbar();
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [schedule, setSchedule] = useState([] as IMatch[]);
  useEffect(() => {
    const init = () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_SCHEDULE, tournament });
    };
    init();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [tournament, dispatch]);

  useEffect(() => {
    const updateSchedule = async () => {
      const response = await store.schedule;
      response.result && setSchedule(response.result.Item.schedule);
    };
    updateSchedule();
  }, [store.schedule]);

  const updateSchedule = (schedule: IMatch[]) => {
    setSchedule(schedule);
  };

  const updateMatchHandler = (index: number, match: IMatch) => {
    const currSchedule = cloneDeep(schedule);
    set(currSchedule, [index], match);
    updateSchedule(currSchedule);
  };

  const _saveSchedule = () => {
    props.setShowLoader(true);
    DashboardAPI.updateSchedule(tournament, schedule)
      .then(() => {
        props.setShowLoader(false);
        enqueueSnackbar("Changed Saved", {
          variant: "success",
        });
      })
      .catch(() => {
        props.setShowLoader(false);
        enqueueSnackbar("Oops! Something went wrong", {
          variant: "error",
        });
      });
  };

  const getMatchEditors = () => {
    return !isEmpty(schedule) ? (
      map(schedule, (match, index) => (
        <Grid item xs={12} key={`match#${index}`}>
          <MatchEditor
            match={match}
            tournament={tournament}
            index={index}
            updateMatchHandler={updateMatchHandler}
          />
        </Grid>
      ))
    ) : (
      <Grid item xs={12}>
          <CircularProgress color="primary" />
      </Grid>
    );
  };

  return (
    <Container maxWidth="lg" className={classes.mainGrid}>
      <main>
        <Intro
          title={tournament}
          description=""
          image="https://source.unsplash.com/bY4cqxp7vos"
          imgText="main image description"
          linkText=""
        />
        <Grid item xs={12}>
         
          <SurvivorStatusBar confidenceScores={[]} save={_saveSchedule} />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper elevation={3} className={classes.leaguesSection}>
              <Title title={"Schedule"} />
              <Grid container spacing={4}>
                {getMatchEditors()}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.saveFab}>
            <Fab
              color="primary"
              aria-label="save"
              variant="extended"
              onClick={_saveSchedule}
            >
              <SaveIcon className={classes.extendedIcon} />
              Save
            </Fab>
          </Grid>
        </Grid>
        </Grid>


      </main>
    </Container>
  );
}
