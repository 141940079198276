import React from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import JumboButton from "./modules/components/JumboButton";
import Box from "@material-ui/core/Box";
import { isEmpty, map } from "lodash-es";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
}));
// ,Live:any,Upcoming:any
export default function FullWidthTabs({
  Data,
  navigateToLeague,
  setSelectedLeague,
  openCreate,
}: any) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const FilterData = (
    allTournament: any,
    type: string,
    openCreate: any,
    setSelectedLeague: any
  ) => {
    return (
      <>
        {/*  */}

        {!isEmpty(allTournament) ?  (
          <>
            {/* 'ok'
             */}
            <Grid container spacing={4}>
              {map(allTournament, (tournament) => (
                <>
                  {tournament.tournamentStatus === type ? (
                    <JumboButton
                      title={tournament.tournament}
                      description={`${tournament.type} leagues type tournament`}
                      image="https://source.unsplash.com/ghxL3qOfkPo"
                      imgText="main image description"
                      // onClick={() =>  openCreate(true)}

                      onClick={() => {
                        setSelectedLeague(tournament);
                        openCreate(true);
                      }}
                    />
                  ) : null}
                </>
              ))}
            </Grid>
          </>
        ) :
        <CircularProgress color="primary" />
        }

        {/*  */}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Upcoming" {...a11yProps(0)} />
          <Tab label="Live" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {FilterData(Data, "Upcoming", openCreate, setSelectedLeague)}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {/* <Live/> */}
          {FilterData(Data, "Live", openCreate, setSelectedLeague)}
          {/* 'ok' */}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
