import { CardHeader } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown-now";
import theme from "../../../../theme";
import { IMatch } from "../../../../api/DashboardAPI";

const useStyles = makeStyles({
  card: {
    display: "flex",
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  cardSelected: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
  },
  rootPaper: {
    padding: theme.spacing(2),
  },
  winnerSelection: {
    justifyContent: "space-evenly",
  },
});

interface IMatchEditorProps {
  match: IMatch;
  tournament: string;
  index: number;
  updateMatchHandler: (index: number, match: IMatch) => void;
}

export interface IPlayerOption {
  team: string;
  player: string;
}

export interface IPlayPlayerOption {
  team: string;
  player: string;
}

export default function MatchEditorPlayer(props: IMatchEditorProps) {
  const classes = useStyles();
  const {
    updateMatchHandler,
     match: {
      left,
      right,
      start,
      completed: matchCompleted,
      mom: matchMom,
      winner,
      end,
    },
    index,
  } = props;

  const [leftTeamName] = useState(left);
  const [rightTeamName] = useState(right);
  const [matchStart] = useState(start);
  const [matchEnd] = useState(end);
  const [completed] = useState(matchCompleted);
  const [mom] = useState(matchMom);
  const [matchWinner] = useState(winner || "");

  useEffect(() => {
    updateMatchHandler(index, {
      left: leftTeamName,
      right: rightTeamName,
      start: matchStart,
      end: matchEnd,
      completed,
      mom,
      winner: matchWinner,
    });
    // only update prediction if team, mom, or confidence is to be updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    leftTeamName,
    rightTeamName,
    matchStart,
    matchEnd,
    mom,
    completed,
    matchWinner,
  ]);

 const _timeRemainingRenderer = ({
    days,
    hours,
    minutes,
    completed,
  }: CountdownRenderProps) => {
    if (matchCompleted || completed)
      return `Winner: ${winner} Man of the match: ${matchMom}`;
    return `${days} days, ${hours} hours, ${minutes} minutes`;
  };

  const startDate = new Date(matchStart);

  return (
    <Card className={classes.rootPaper} variant="outlined">
      <CardHeader
        title={
          <>
          <Box fontWeight="fontWeightBold" fontSize="h4.fontSize">
            {`Match #${index + 1}`}
          </Box>
        
           <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
           {`${left} VS ${right}`}
         </Box>
         </>
        }
        subheader={
          <>
            <div>{`${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()}`}</div>
            <Countdown date={startDate} renderer={_timeRemainingRenderer} />
          </>
        }
      />
    </Card>
  );
}
