import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { URL } from "../../../../Routes";
import { isEmpty, sortBy, reverse, get, map } from "lodash-es";
import { LEAGUE_ACTIONS } from "../../../../reducers/LeagueReducer";
import { useDispatch, useSelector } from "react-redux";
import { reducers } from "../../../../reducers";
import { CircularProgress, Paper } from "@material-ui/core";
import Title from "../../../dashboard/modules/components/Title";
import { ILeague } from "../../../../api/LeagueAPI";
import SearchIcon from "@material-ui/icons/Search";
import Leagjoin from "./Leagjoin";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import LeagueAPI from "../../../../api/LeagueAPI";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  leaguesSection: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    placeholderTextColor: "#ffffff",
    backgroundColor: "#42a5f5",
    color: "#ffffff",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "125ch",
      "&:focus": {
        width: "125ch",
      },
    },
  },
  root: {
    minWidth: 275,
  },
  cardsize: {
    height: 130,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  buttonJustify: {
    justifyContent: "space-between",
  },
}));

interface IDashboardProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  history: any;
  match: {
    params: { [key: string]: string };
  };
  league: ILeague;
  open: boolean;
  handleClose: () => void;
  setShowLoader :any;
}

export default function JoinLeagues(props: IDashboardProps) {
  if (!props.isAuthenticated) props.history.push(URL.HOME);

  const currentUrl = window.location.pathname;
  const currentUrl22 = currentUrl.split("/");
  const tournament = currentUrl22[2];
  const classes = useStyles();
  const dispatch = useDispatch();
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [adminLeagues, setAdminLeagues] = useState([] as ILeague[]);
  const [selectedLeague, setSelectedLeague] = useState({} as ILeague);
  const [isManageOpen, openManage] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredLeagus, setFilterededLeagus] = useState([] as ILeague[]);
  const [passordError, SetStateError] = useState(false);
  const [passordNullError, SetNullError] = useState(false);
  const [members, setMembers] = useState([] as string[]);
  const { enqueueSnackbar } = useSnackbar();

  //const tournament = props.match.params.game;

    // constructor and destructor
    useEffect(() => {
      return function cleanup() {
        setMembers([]);
      };
    }, []);
  
    // store watcher
    useEffect(() => {
      const updateMembers = async () => {
        const response = await store.members;
        setMembers(map(get(response, "result.Items", {}), "userId"));
      };
      updateMembers();
    }, [store.members]);
  

  useEffect(() => {
    const getAllLeague = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_LEAGUES, tournament });
    };
    getAllLeague();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch, tournament]);

  // store watcher
  useEffect(() => {
    const updateAdminLeagues = async () => {
      const leagues = await store.all_leagues;
      const sortedAdminLeagues = reverse(
        sortBy(get(leagues, "result"), (l) => l.created || "")
      );
      setAdminLeagues(sortedAdminLeagues);
    };
    updateAdminLeagues();
  }, [store.all_leagues]);

  const saveChanges = (
    password: any,
    finalPassword: any,
    leagueName: any,
    tournament: any,
    type: any
  ) => { 
    if (password.length !== 0) {
      if (password === finalPassword) {
        SetNullError(false);
        SetStateError(false);
        props.setShowLoader(true);
        LeagueAPI.setJoinLeagueMembers(
          tournament,
          leagueName,
          password,
          type,
          members
        )
          .then((response) => {
            props.setShowLoader(false);
            setMembers(map(get(response, "result.Items", {}), "userId"));
            openManage(false);
            enqueueSnackbar("League Joined successfully", {
              variant: "success",
            });
            getAllLeague();
          })
          .catch((error) => {
            props.setShowLoader(false);
            enqueueSnackbar("Oops! Something went wrong", {
              variant: "error",
            });
          });
      } else {
        SetStateError(true);
      }
      SetNullError(false);
    } else {
      SetNullError(true);
      SetStateError(false);
    }
  };
  const getAllLeague = async () => {
    dispatch({ type: LEAGUE_ACTIONS.GET_ALL_LEAGUES, tournament });
  };
  useEffect(() => {
    return setFilterededLeagus(
      adminLeagues.filter((league) => {
        return Object.values(league)
          .join(" ")
          .toLowerCase()
          .includes(search.toLowerCase());
      })
    );
  }, [search, adminLeagues]);

  function Leguesdata() {
    return (
      <>
        <Grid container spacing={4} style={{ marginTop: 20 }}>
          {!isEmpty(adminLeagues) ? (
            <Grid item xs={12}>
              <Paper elevation={3} className={classes.leaguesSection}>
                <Title title="Join Leagues" />
                <Grid container spacing={2}>
                  {filteredLeagus.map((league) => (
                    <Grid item xs={12} sm={6} md={4} key={league.leagueName}>
                      <Card className={classes.root}>
                        <CardContent className={classes.cardsize}>
                          <Typography variant="h5" component="h2">
                            League - {league.leagueName}
                          </Typography>
                          <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            Description - {league.description}
                          </Typography>
                          <Typography
                            className={classes.title}
                            color="primary"
                            gutterBottom
                          >
                            Type - {league.type} League
                          </Typography>
                          <Typography
                            className={classes.title}
                            color="primary"
                            gutterBottom
                          >
                            tournamnet - {league.tournament}
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions className={classes.buttonJustify}>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setSelectedLeague(league);
                              dispatch({
                                type: LEAGUE_ACTIONS.GET_LEAGUE_MEMBERS,
                                tournament,
                                leagueName: league.leagueName,
                                password: league.password,
                                leagueType: league.type
                              });
                              openManage(true);
                            }}
                          >
                            Join League
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <CircularProgress color="primary" />
            </Grid>
          )}
        </Grid>
      </>
    );
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.mainGrid}>
        <main>
          <Grid item xs={12}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                onChange={(e) => setSearch(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Grid>
          <Leguesdata />
          <Leagjoin
            open={isManageOpen}
            league={selectedLeague}
            handleClose={() => openManage(false)}
            handleSubmit={saveChanges}
            passordNullError={passordNullError}
            passordError={passordError}
          />
        </main>
      </Container>
    </React.Fragment>
  );
}
