import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Intro from "./modules/components/Intro";
// import JumboButton from "./modules/components/JumboButton";
import { URL } from "../../Routes";
import CreateLeagueDialog from "./modules/components/CreateLeagueDialog";
import CreateLeagueDialogTournament from "./modules/components/CreateLeagueDialogTournament";
import { cloneDeep, isEmpty, reduce,  sortBy, reverse, get } from "lodash-es";
import { check } from "../landing/modules/form/validation";
import LeagueAPI, { ILeague } from "../../api/LeagueAPI";
import { LEAGUE_ACTIONS } from "../../reducers/LeagueReducer";
import { reducers } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Paper } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { useSnackbar } from "notistack";
import TournamnetView from "./TouranamentListSwapableViewLive_upComing";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  leaguesSection: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

interface IDashboardProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  history: any;
  setShowLoader: any;
}

export function CreateLeagueDialogContainer(props: {
  isCreateOpen: boolean;
  openCreate: React.Dispatch<React.SetStateAction<boolean>>;
   setShowLoader :any;
}) {
  const dispatch = useDispatch();
  const { isCreateOpen, openCreate } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [tournamnetNullError, SetTournamnetNullError] = useState(false);
  const [leagueTypeNullError, SetLeagueTypeNullError] = useState(false);

  const [formFields, setFormField] = useState({
    leagueName: "",
    description: "",
    password: ""
  });

  const [helperTexts, setHelperText] = useState({
    leagueName: "",
    description: "",
    password: ""
  });

  const fields = [
    { id: "leagueName", label: "League Name" },
    { id: "description", label: "Description" },
    { id: "password", label: "Password" },
  ];

  const validate = () => {
    const errors = cloneDeep(helperTexts);
    check(isEmpty(formFields.leagueName), "leagueName", "Required", errors);
    check(
      formFields.leagueName.length < 3,
      "leagueName",
      "League name must have minimum 3 characters",
      errors
    );

    check(isEmpty(formFields.password), "password", "Required", errors);
    check(
      formFields.password.length < 3,
      "password",
      "Password is Required",
      errors
    );

    setHelperText(errors);
    return reduce(
      errors,
      (acc, value) => {
        return acc && isEmpty(value);
      },
      true
    );
  };

  const createLeague = async (tournament: string, leagueType : string) => {
if (validate() && tournament.length !== 0 && leagueType.length !== 0) {
     
      const payload: ILeague = {
        ...formFields,
        tournament,
        totalPowerPlayPoints: 3000,
        userId: "",
        type: "",
        leagueType
      };
      SetLeagueTypeNullError(false);
      SetTournamnetNullError(false);
      props.setShowLoader(true);
      LeagueAPI.create(payload)
        .then(() => {
          props.setShowLoader(false);
          openCreate(false);
          enqueueSnackbar("League Created successfully", {
            variant: "success",
          });
          dispatch({ type: LEAGUE_ACTIONS.GET_USER_LEAGUES });
 
        })
        .catch((err) => {
          props.setShowLoader(false);
          const errors = cloneDeep(helperTexts);
          if (err.response.data.error.message === "Already Exists") {
            check(
              true,
              "leagueName",
              "League already exists! Please choose a different name.",
              errors
            );
            check(isEmpty(formFields.password), "password", "Required", errors);
            check(
              formFields.password.length < 3,
              "password",
              "Password is Required",
              errors
            );
          } else {
            check(
              true,
              "leagueName",
              "Something went wrong! Please try again.",
              errors
            );
            check(
              true,
              "password",
              "Password is Required",
              errors
            );
          }
          setHelperText(errors);
        });
    }else{SetLeagueTypeNullError(true); SetTournamnetNullError(true);}
  
  };

  return (
    <CreateLeagueDialog<{ leagueName: string; description: string; password: string;}>
      open={isCreateOpen}
      fields={fields}
      formFields={formFields}
      setFormField={setFormField}
      helperTexts={helperTexts}
      handleClose={() => openCreate(false)}
      handleSubmit={createLeague}   
      leagueTypeNullError={leagueTypeNullError} 
      tournamnetNullError={tournamnetNullError} 

      />
  );
}

export function CreateLeagueDialogContainerTournament(props: {
  isCreateOpen: boolean;
  openCreate: React.Dispatch<React.SetStateAction<boolean>>;
  tournament: any;
  setShowLoader :any;

  
}) {
  const dispatch = useDispatch();
  const { isCreateOpen, openCreate, tournament } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [leagueTypeNullError, SetLeagueTypeNullError] = useState(false);

  const [formFields, setFormField] = useState({
    leagueName: "",
    description: "",
    password: ""
  });

  const [helperTexts, setHelperText] = useState({
    leagueName: "",
    description: "",
    password: "",
  });

  const fields = [
    { id: "leagueName", label: "League Name" },
    { id: "description", label: "Description" },
    { id: "password", label: "Password" },
  ];

  const validate = () => {
    const errors = cloneDeep(helperTexts);
    check(isEmpty(formFields.leagueName), "leagueName", "Required", errors);
    check(
      formFields.leagueName.length < 3,
      "leagueName",
      "League name must have minimum 3 characters",
      errors
    );

    check(isEmpty(formFields.password), "password", "Required", errors);
    check(
      formFields.password.length < 3,
      "password",
      "Password is Required",
      errors
    );

    setHelperText(errors);
    return reduce(
      errors,
      (acc, value) => {
        return acc && isEmpty(value);
      },
      true
    );
  };

  const createLeague = async (tournament: string, leagueType: string) => {
    if (validate() && leagueType.length !== 0) {
      SetLeagueTypeNullError(false);
      const payload: ILeague = {
        ...formFields,
        tournament,
        totalPowerPlayPoints: 3000,
        userId: "",
        type: "",
        leagueType 
      };
      props.setShowLoader(true);
      LeagueAPI.create(payload)
        .then(() => {
          props.setShowLoader(false);
          openCreate(false);
          enqueueSnackbar("League Created successfully", {
            variant: "success",
          });
          dispatch({ type: LEAGUE_ACTIONS.GET_USER_LEAGUES });
        })
        .catch((err) => {
          props.setShowLoader(false);
          const errors = cloneDeep(helperTexts);

          if (err.response.data.error.message === "Already Exists") {
            check(
              true,
              "leagueName",
              "League already exists! Please choose a different name.",
              errors
            );
            check(isEmpty(formFields.password), "password", "Required", errors);
            check(
              formFields.password.length < 3,
              "password",
              "Password is Required",
              errors
            );
           
          } else {
            check(
              true,
              "leagueName",
              "Something went wrong! Please try again.",
              errors
            );
            check(
              true,
              "password",
              "Password is Required",
              errors
            );
          }
          setHelperText(errors);
        });
    }else{ SetLeagueTypeNullError(true);}
  };

  return (
    <CreateLeagueDialogTournament<{ leagueName: string; description: string; password: string; }>
      open={isCreateOpen}
      fields={fields}
      formFields={formFields}
      setFormField={setFormField}
      helperTexts={helperTexts}
      handleClose={() => openCreate(false)}
      handleSubmit={createLeague}  
      type={tournament}
      leagueTypeNullError={leagueTypeNullError} 
      />
  );
}

export default function CreateLeague(props: IDashboardProps) {
  if (!props.isAuthenticated) props.history.push(URL.HOME);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isCreateOpen, openCreate] = useState(false);
  
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [allTournament, setAllTournament] = useState([] as ILeague[]);
  const [selectedLeague, setSelectedLeague] = useState({} as ILeague);


  // constructor and destructor
  useEffect(() => {
    const getUserLeagues = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_USER_LEAGUES });
    };
    getUserLeagues();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);


  useEffect(() => {
    const getAllTournament = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_TOURNAMENT});
    };
    getAllTournament();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  // store watcher
  useEffect(() => {
    const updateAllTournament = async () => {
      const tournaments = await store.all_tournament;
      const sortedAllTournamnet= reverse(sortBy(get(tournaments, "result.Items"), (l) => l.created || ""));
     
       setAllTournament(sortedAllTournamnet);
    };
    updateAllTournament();
  }, [store.all_tournament]);

  const navigateToMyLeagues = () => {
    const url = URL.LEAGUES.MY_TOURNAMENT;
    props.history.push(url);
  };
  const navigateToHome = () => {
    const url = URL.LEAGUES.HOME;
    props.history.push(url);
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.mainGrid}>
        <main>
          <Intro
            title="Create a League"
            description=""
            image="https://source.unsplash.com/oDs_AxeR5g4"
            imgText="main image description"
            linkText=""
          />
            <Grid item xs={12} spacing={4} style={{marginBottom: 10}}>
              <Paper className={classes.leaguesSection}>
                <div role="presentation">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() =>
                    navigateToHome()}>
                      Home
                    </Link>
                    <Link underline="hover" color="inherit" onClick={() =>
                    navigateToMyLeagues()}>
                      My Leagues
                    </Link>
                    <Link
                      underline="hover"
                      color="primary"
                      aria-current="page"
                    >
                     Create leagues
                    </Link>
                  </Breadcrumbs>
                </div>
              </Paper>
            </Grid>
            <TournamnetView Data={allTournament} navigateToLeague={''} openCreate={openCreate} setSelectedLeague={setSelectedLeague} />
            <CreateLeagueDialogContainerTournament
            isCreateOpen={isCreateOpen}
            openCreate={openCreate}
            tournament={selectedLeague}
            setShowLoader={props.setShowLoader}
          />
          
        </main>
      </Container>
    </React.Fragment>
  );
}
