import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { URL } from "../../Routes";
import { LEAGUE_ACTIONS } from "../../reducers/LeagueReducer";
import { CreateLeagueDialogContainer } from "../dashboard/CreateLeague";
// import JumboButton from "../dashboard/modules/components/JumboButton";
import { reducers } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import { sortBy, reverse, get } from "lodash-es";
import { ILeague } from "../../api/LeagueAPI";
import JoinLeaguesViews from "./JoinLeagesSwapableView";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  leaguesSection: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    placeholderTextColor: "#ffffff",
    backgroundColor: "#42a5f5",
    color: "#ffffff",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

interface IDashboardProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  match: {
    params: { [key: string]: string };
  };
  history: any;
  setShowLoader :any;
}

export default function Leag(props: IDashboardProps) {
  if (!props.isAuthenticated) props.history.push(URL.HOME);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isCreateOpen, openCreate] = useState(false);

  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [allTournament, setAllTournament] = useState([] as ILeague[]);


  useEffect(() => {
    const getAllTournament = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_TOURNAMENT });
    };
    getAllTournament();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  useEffect(() => {
    const updateAllTournament = async () => {
      const tournaments = await store.all_tournament;
      const sortedAllTournamnet = reverse(
        sortBy(get(tournaments, "result.Items"), (l) => l.created || "")
      );
      setAllTournament(sortedAllTournamnet);
    };
    updateAllTournament();
  }, [store.all_tournament]);

  const navigateToLeague = (tournament: string) => {
    const url = URL.LEAGUES.JOINLEAGUES.replace(":game", tournament);
    props.history.push(url);
  };

  return (
    <>
      <React.Fragment>
        {/* ok */}
        <Container maxWidth="lg" className={classes.mainGrid}>
          <main>
            <JoinLeaguesViews Data={allTournament} navigateToLeague={navigateToLeague} />
            {/* <TournamnetView  /> */}
            {/* {!isEmpty(allTournament) && (
              <Grid>
                {map(allTournament, (tournament) => (
                  <Grid item
                    container
                    alignItems="center"
                    justify="center"
                    spacing={2}
                    xs={12}
                    key={tournament.tournament}
                  >
                    <JumboButton
                      title={`${tournament.tournament} League`}
                      description=""
                      image="https://source.unsplash.com/ghxL3qOfkPo"
                      imgText="main image description"
                      onClick={() => navigateToLeague(tournament.tournament)}
                    />
                  </Grid>
                ))}
              </Grid>
            )} */}
          </main>
          <CreateLeagueDialogContainer
            isCreateOpen={isCreateOpen}
            openCreate={openCreate}
            setShowLoader={props.setShowLoader}
          />
        </Container>
      </React.Fragment>
    </>
  );
}
