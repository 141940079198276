import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { IPlayerOption } from "./TeamSwitcher";
import LeagueAPI, { IPlayerListResult } from "../../../../api/LeagueAPI";
import Typography from "@material-ui/core/Typography";
import { isEmpty, map, get, times } from "lodash-es";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DashboardAPI, {
  IPlayerScorePrediction,
  IPlayerGetScoreBoardResult,
} from "../../../../api/DashboardAPI";
import { useSnackbar } from "notistack";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import "../../../../App.css";
import { CircularProgress } from "@material-ui/core";

interface IPlayerAutocompleteProps {
  value: IPlayerOption;
  // match: IPlayerScoreBoard;
  tournament: string;
  leftTeam: string;
  rightTeam: string;
  index: number;
  wrong?: boolean;
  correct?: boolean;
  isOptionDisabled: any;
  leftTeamList: IPlayerOption[];
  open: boolean;
  handleClose: () => void;
  teamOpen: () => void;
  setShowLoader: any;
  // playerCountMap: any;
  // updateMatchScoreHandler: (index: number, match: IPlayerScoreBoard) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  value: any;
  index: number;
}

interface User {
  name: string;
  age: number | string;
}

interface ITeamScore {
  [x: string]: any;
  Team: string;
  PlayerName: string;
  batter_runs: number;
  batter_balls: number;
  batter_status: number;
  batter_factor: number;
  bowler_runs: number;
  bowler_balls: number;
  bowler_wickets: number;
  bowler_factor: number;
}

interface ITeamScoreBoard {
  total_runs: number;
  total_balls: number;
  total_wickets: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 112,
    },
    selectRoot: {
      // marginTop: theme.spacing(1),
      // marginBottom: theme.spacing(1),
      marginRight: theme.spacing(2),
      width: 112,
    },
    leftLinkActive: {
      color: theme.palette.common.white,
    },
    leftText: {
      textAlign: "left",
    },
    centerText: {
      textAlign: "center",
    },
    appBar: {
      position: "fixed",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: theme.palette.common.white,
    },
    tabRoot: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(8),
    },
    swipeable: {
      marginTop: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
    rootbtn: {
      height: 55,
      color: "#ffffff",
    },
    inputsetup: {
      margin: theme.spacing(1),
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PlayerList(props: IPlayerAutocompleteProps) {
  const theme = useTheme();
  const classes = useStyles();
  const [TeamValue, setTeamValue] = useState(props.leftTeam);

  const [playerList, setPlayerList] = useState<ITeamScore[]>([]);

  const [state, setState] = useState({} as ITeamScoreBoard);
  const handleTotalScoreChange = (e: any) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /////////////////
  const [playerCountdata, setPlayerCountData] = useState(new Map([]));
  // console.log(props.playerCountMap);
  // useEffect(()=>{
  //   setPlayerCountData(new Map(props.playerCountMap))
  //   // setMatchSocre(true)

  // },[props.playerCountMap])
  // console.log(playerCountdata);

  const getCount = (PlayeyName: string, type: string) => {
    const obj: any = playerCountdata.get(PlayeyName);
    if (obj) {
      return obj[type];
    }
    return 0;
  };
  //////////////////
  const handleLeftChange = (e: any) => {
    const { name, id, value } = e.target;
    const list = JSON.parse(JSON.stringify(playerList));
    let findPlayer = list.find(
      (item: { PlayerName: any }) => item.PlayerName === id
    );
    const index = list.findIndex(
      (item: { PlayerName: any }) => item.PlayerName === id
    );
    if (findPlayer) {
      findPlayer[name] = value;
      list.splice(index, 1, findPlayer);
    } else {
      list.push({
        Team: TeamValue,
        PlayerName: id,
        [name]: value,
      });
    }
    setPlayerList(list);
  };

  // localStorage.setItem("Team_Score", JSON.stringify(playerList));
  // localStorage.getItem("Team_Score");

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const [players_score] = useState<ITeamScore[]>([]);
  const [matchSocre, setMatchSocre] = useState(false);
  const playerScoreLoading = matchSocre && players_score.length === 0;

  useEffect(() => {
    let active = true;
    if (!playerScoreLoading) {
      return undefined;
    }
    (async () => {
      const TopPridictedPlayersCount = await LeagueAPI.getTopPridictedPlayersCount(
        tournament,
        match_no
      );
      const playerScoreResponse = await DashboardAPI.getTeamScoreBoard(
        tournament,
        match_no
      );
      const playersScoreBoard = [
        ..._playerScoreBoardResponseFormatter(playerScoreResponse),
      ];
      const totalScore = get(
        playerScoreResponse,
        "result.Item",
        times(tournament.length, () => ({
          total_runs: "",
          total_balls: "",
          total_wickets: "",
        }))
      );
      // useEffect(() => {

      // (async () => {

      // const map = new Map(TopPridictedPlayersCount);
      console.log(TopPridictedPlayersCount);

      // })();

      // }, [tournament])

      if (active) {
        setPlayerList(playersScoreBoard);
        setState(totalScore);
        setPlayerCountData(new Map(TopPridictedPlayersCount));
      }
    })();
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerScoreLoading]);

  const _playerScoreBoardResponseFormatter = (
    playerResponse: IPlayerGetScoreBoardResult
  ) => {
    const { players_score } = get(playerResponse, "result.Item", {});
    return map(players_score, (item) => ({
      Team: item.Team,
      PlayerName: item.PlayerName,
      batter_runs: item.batter_runs,
      batter_balls: item.batter_balls,
      batter_status: item.batter_status,
      batter_factor: item.batter_factor,
      bowler_runs: item.bowler_runs,
      bowler_balls: item.bowler_balls,
      bowler_wickets: item.bowler_wickets,
      bowler_factor: item.bowler_factor,
    }));
  };
  const [rightPlayers, setRightPlayers] = useState<IPlayerOption[]>([]);

  const [rightOpenPlayers, setRightOpenPlayers] = useState(false);
  const rightLoading = rightOpenPlayers && rightPlayers.length === 0;
  useEffect(() => {
    let active = true;
    if (!rightLoading) {
      return undefined;
    }
    (async () => {
      const rightPlayerResponse = await LeagueAPI.getPlayers(
        props.tournament,
        props.rightTeam
      );
      const players = [..._rightPlayerResponseFormatter(rightPlayerResponse)];
      if (active) {
        setRightPlayers(players);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightLoading]);

  const _rightPlayerResponseFormatter = (playerResponse: IPlayerListResult) => {
    const { team, players } = get(playerResponse, "result.Item", {});
    return map(players, (player) => ({ team, player }));
  };

  const [match_no] = useState(props.index + 1);
  const [tournament] = useState(props.tournament);
  const _saveSchedule = (e: any) => {
    e.preventDefault();
    const payload: IPlayerScorePrediction = {
      total_runs: state.total_runs,
      total_balls: state.total_balls,
      total_wickets: state.total_wickets,
      players_score: playerList,
    };
    props.setShowLoader(true);
    DashboardAPI.setPlayerScoreBoardPrediction(
      props.tournament,
      match_no,
      payload
    )
      .then(() => {
        props.setShowLoader(false);
        enqueueSnackbar("Changed Saved", {
          variant: "success",
        });
      })
      .catch(() => {
        props.setShowLoader(false);
        enqueueSnackbar("Oops! Something went wrong", {
          variant: "error",
        });
      });
  };

  const handleLeftTeam = () => {
    setTeamValue(props.leftTeam);
    setMatchSocre(true);
  };

  const handleRightTeam = () => {
    setRightOpenPlayers(true);
    setTeamValue(props.rightTeam);
    setMatchSocre(true);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.leftLinkActive}
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.leftTeam}&nbsp;Vs&nbsp;{props.rightTeam}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              className={classes.leftLinkActive}
              onClick={_saveSchedule}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <AppBar position="fixed" color="default" className={classes.tabRoot}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
            style={{ backgroundColor: theme.palette.background.paper }}
          >
            <Tab
              label={props.leftTeam}
              {...a11yProps(0)}
              onClick={handleLeftTeam}
            />
            <Tab
              label={props.rightTeam}
              {...a11yProps(1)}
              onClick={handleRightTeam}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          className={classes.swipeable}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Grid
              container
              style={{
                marginTop: 100,
                marginBottom: 5,
                justifyContent: "center",
              }}
              component={Paper}
            >
              <Grid item xs={12} md={2} className={classes.inputsetup}>
                <Typography component="h5">Match Total Score Set</Typography>
              </Grid>
              <Grid item xs={12} md={3} className={classes.inputsetup}>
                <TextField
                  id="outlined-basic"
                  label="Total Run"
                  variant="outlined"
                  name="total_runs"
                  fullWidth
                  value={state.total_runs}
                  type="number"
                  onChange={handleTotalScoreChange}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.inputsetup}>
                <TextField
                  id="outlined-basic"
                  label="Total Ball"
                  variant="outlined"
                  name="total_balls"
                  fullWidth
                  type="number"
                  value={state.total_balls}
                  onChange={handleTotalScoreChange}
                />
              </Grid>
              <Grid item xs={12} md={3} className={classes.inputsetup}>
                <TextField
                  id="outlined-basic"
                  label="Total wicket"
                  variant="outlined"
                  name="total_wickets"
                  fullWidth
                  value={state.total_wickets}
                  type="number"
                  onChange={handleTotalScoreChange}
                />
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Player Name</TableCell>
                    <TableCell align="center">Batter</TableCell>
                    <TableCell align="center">Bowler</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {!isEmpty(props.leftTeamList) ? (
                      map(props.leftTeamList, (player) => (
                        <>
                          {/* {map(players_score, (item) => ( */}
                          <>
                            <TableRow key={player.player}>
                              <TableCell component="th" scope="row">
                                {player.team}&nbsp;{player.player}&nbsp;
                              </TableCell>

                              <TableCell>
                                <TextField
                                  id={player.player}
                                  name="batter_runs"
                                  label="factor count"
                                  variant="outlined"
                                  disabled
                                  className={classes.root}
                                  type="text"
                                  // onChange={handleLeftChange}
                                  value={getCount(player.player, "batCount")}
                                />
                                <TextField
                                  id={player.player}
                                  name="batter_runs"
                                  label="Run"
                                  variant="outlined"
                                  className={classes.root}
                                  type="number"
                                  onChange={handleLeftChange}
                                  value={
                                    playerList.find(
                                      (el) => el.PlayerName === player.player
                                    )?.batter_runs
                                  }
                                />
                                <TextField
                                  id={player.player}
                                  name="batter_balls"
                                  label="Ball"
                                  variant="outlined"
                                  className={classes.root}
                                  type="number"
                                  onChange={handleLeftChange}
                                  value={
                                    playerList.find(
                                      (el) => el.PlayerName === player.player
                                    )?.batter_balls
                                  }
                                />

                                <FormControl
                                  variant="outlined"
                                  className={classes.selectRoot}
                                >
                                  <select
                                    id={player.player}
                                    className="select select-text"
                                    name="batter_status"
                                    onChange={handleLeftChange}
                                    value={
                                      playerList.find(
                                        (el) => el.PlayerName === player.player
                                      )?.batter_status
                                    }
                                  >
                                    <option value={0}>0</option>
                                    <option value={1}>1</option>
                                  </select>
                                </FormControl>
                                <TextField
                                  id={player.player}
                                  name="batter_factor"
                                  label="Factor"
                                  variant="outlined"
                                  className={classes.root}
                                  type="number"
                                  onChange={handleLeftChange}
                                  value={
                                    playerList.find(
                                      (el) => el.PlayerName === player.player
                                    )?.batter_factor
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  id={player.player}
                                  name="batter_runs"
                                  label="factor count"
                                  variant="outlined"
                                  className={classes.root}
                                  type="text"
                                  disabled
                                  // onChange={handleLeftChange}
                                  value={getCount(player.player, "bowlCount")}
                                />
                                <TextField
                                  id={player.player}
                                  name="bowler_runs"
                                  label="Run"
                                  variant="outlined"
                                  className={classes.root}
                                  type="number"
                                  onChange={handleLeftChange}
                                  value={
                                    playerList.find(
                                      (el) => el.PlayerName === player.player
                                    )?.bowler_runs
                                  }
                                />
                                <TextField
                                  id={player.player}
                                  name="bowler_balls"
                                  label="Ball"
                                  variant="outlined"
                                  className={classes.root}
                                  type="number"
                                  onChange={handleLeftChange}
                                  value={
                                    playerList.find(
                                      (el) => el.PlayerName === player.player
                                    )?.bowler_balls
                                  }
                                />
                                <TextField
                                  id={player.player}
                                  name="bowler_wickets"
                                  label="Wicket"
                                  variant="outlined"
                                  className={classes.root}
                                  type="number"
                                  onChange={handleLeftChange}
                                  value={
                                    playerList.find(
                                      (el) => el.PlayerName === player.player
                                    )?.bowler_wickets
                                  }
                                />
                                <TextField
                                  id={player.player}
                                  name="bowler_factor"
                                  label="Factor"
                                  variant="outlined"
                                  className={classes.root}
                                  type="number"
                                  onChange={handleLeftChange}
                                  value={
                                    playerList.find(
                                      (el) => el.PlayerName === player.player
                                    )?.bowler_factor
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        </>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell>
                          <CircularProgress color="primary" />
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="simple table"
                style={{ marginTop: 80 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Player Name</TableCell>
                    <TableCell align="center">Batter</TableCell>
                    <TableCell align="center">Bowler</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty(rightPlayers) ? (
                    map(rightPlayers, (player) => (
                      <>
                        <TableRow key={player.player}>
                          <TableCell component="th" scope="row">
                            {player.team}&nbsp;{player.player}
                          </TableCell>
                          <TableCell>
                            <TextField
                              id={player.player}
                              name="batter_runs"
                              label="factor count"
                              variant="outlined"
                              className={classes.root}
                              type="text"
                              disabled
                              // onChange={handleLeftChange}
                              value={getCount(player.player, "batCount")}
                            />
                            <TextField
                              id={player.player}
                              name="batter_runs"
                              label="Run"
                              variant="outlined"
                              className={classes.root}
                              type="number"
                              onChange={handleLeftChange}
                              value={
                                playerList.find(
                                  (el) => el.PlayerName === player.player
                                )?.batter_runs
                              }
                            />
                            <TextField
                              id={player.player}
                              label="Ball"
                              variant="outlined"
                              className={classes.root}
                              type="number"
                              name="batter_balls"
                              onChange={handleLeftChange}
                              value={
                                playerList.find(
                                  (el) => el.PlayerName === player.player
                                )?.batter_balls
                              }
                            />
                            <FormControl
                              variant="outlined"
                              className={classes.selectRoot}
                            >
                              <select
                                id={player.player}
                                className="select select-text"
                                name="batter_status"
                                onChange={handleLeftChange}
                                value={
                                  playerList.find(
                                    (el) => el.PlayerName === player.player
                                  )?.batter_status
                                }
                              >
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                              </select>
                            </FormControl>
                            <TextField
                              id={player.player}
                              name="batter_factor"
                              label="Factor"
                              variant="outlined"
                              className={classes.root}
                              type="number"
                              onChange={handleLeftChange}
                              value={
                                playerList.find(
                                  (el) => el.PlayerName === player.player
                                )?.batter_factor
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              id={player.player}
                              name="batter_runs"
                              label="factor count"
                              variant="outlined"
                              className={classes.root}
                              type="text"
                              disabled
                              // onChange={handleLeftChange}
                              value={getCount(player.player, "bowlCount")}
                            />
                            <TextField
                              id={player.player}
                              label="Run"
                              variant="outlined"
                              className={classes.root}
                              type="number"
                              name="bowler_runs"
                              onChange={handleLeftChange}
                              value={
                                playerList.find(
                                  (el) => el.PlayerName === player.player
                                )?.bowler_runs
                              }
                            />
                            <TextField
                              id={player.player}
                              label="Ball"
                              variant="outlined"
                              className={classes.root}
                              type="number"
                              name="bowler_balls"
                              onChange={handleLeftChange}
                              value={
                                playerList.find(
                                  (el) => el.PlayerName === player.player
                                )?.bowler_balls
                              }
                            />
                            <TextField
                              id={player.player}
                              name="bowler_wickets"
                              label="Wicket"
                              variant="outlined"
                              className={classes.root}
                              type="number"
                              onChange={handleLeftChange}
                              value={
                                playerList.find(
                                  (el) => el.PlayerName === player.player
                                )?.bowler_wickets
                              }
                            />
                            <TextField
                              id={player.player}
                              name="bowler_factor"
                              label="Factor"
                              variant="outlined"
                              className={classes.root}
                              type="number"
                              onChange={handleLeftChange}
                              value={
                                playerList.find(
                                  (el) => el.PlayerName === player.player
                                )?.bowler_factor
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <CircularProgress color="primary" />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </SwipeableViews>
      </Dialog>
    </React.Fragment>
  );
}
