import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { URL } from "../../Routes";
import { isEmpty, map, sortBy, reverse, get } from "lodash-es";
import { LEAGUE_ACTIONS } from "../../reducers/LeagueReducer";
import { useDispatch, useSelector } from "react-redux";
import { reducers } from "../../reducers";
import ManageLeagueDialog from "./modules/components/ManageLeagueDialog";
import { ILeague } from "../../api/LeagueAPI";
import { CreateLeagueDialogContainer } from "./CreateLeague";
import JumboButton from "./modules/components/JumboButton";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { CircularProgress, Paper } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ManageLeagueTabs from "../dashboard/modules/components/ManageLeagueTabs";
import Box from '@material-ui/core/Box';
// import JumboButton from "./modules/components/JumboButton";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles((theme) => ({
  cardroot: {
    minWidth: 275,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  breadcrumbs: {
    "& > * + *": {
      marginTop: theme.spacing(2),
      marginBottom: 10,
    },
  },
  leagueTitle: {
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.dark + " !important",
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  leaguesSection: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  cardsize: {
    height: 130,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  buttonJustify: {
    justifyContent: "space-between",
  },
  playPlayer: {
    fontColor: "#0063cc",
    borderColor: "#0063cc",
  },
  cardHeader: {
    backgroundColor: "#42a5f5",
    color: "#FFFFFF",
  },
  tabTitle: {
    backgroundColor: "#ffffff",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "90.00%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionStyle: {
    padding: "1px 0px",
  },
  tabSelected: {
    color: theme.palette.primary.dark + " !important",
  },
}));

interface IDashboardProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  history: any;
  setShowLoader:any;
}

/////////
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
///////

export default function ManageLeagues(props: IDashboardProps) {
  if (!props.isAuthenticated) props.history.push(URL.HOME);
  const classes = useStyles();
  const dispatch = useDispatch();
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [, setAdminLeagues] = useState([] as ILeague[]);
  const [, setUserLeagues] = useState([] as ILeague[]);
  const [selectedLeague] = useState({} as ILeague);
  const [isManageOpen, openManage] = useState(false);
  const [isCreateOpen, openCreate] = useState(false);

  const [allTournament, setAllTournament] = useState([] as any);
  /////////
  const theme = useTheme();
  const [value, setValue] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };///
  //////////
  // constructor and destructor
  useEffect(() => {
    const getUserLeagues = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_USER_LEAGUES });
    };
    getUserLeagues();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  // store watcher
  useEffect(() => {
    const updateUserLeagues = async () => {
      const leagues = await store.user_leagues;

      const sortedAdminLeagues = reverse(
        sortBy(
          get(leagues, "result.Items[0].adminLeagues"),
          (l) => l.created || ""
        )
      );

      const sortedUserLeagues = reverse(
        sortBy(
          get(leagues, "result.Items[0].userLeagues"),
          (l) => l.created || ""
        )
      );
      setAdminLeagues(sortedAdminLeagues);
      setUserLeagues(sortedUserLeagues);
    };
    updateUserLeagues();
  }, [store.user_leagues]);
  useEffect(() => {
    const getAllTournament = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_TOURNAMENT });
    };
    getAllTournament();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  // store watcher
  useEffect(() => {
    const updateAllTournament = async () => {
      const tournaments = await store.all_tournament;
      const sortedAllTournamnet = reverse(
        sortBy(get(tournaments, "result.Items"), (l) => l.created || "")
      );
      setAllTournament(sortedAllTournamnet);
    };
    updateAllTournament();
  }, [store.all_tournament]);

  const navigateToMyLeagues = () => {
    const url = URL.LEAGUES.MY_TOURNAMENT;
    props.history.push(url);
  };
  const navigateToHome = () => {
    const url = URL.LEAGUES.HOME;
    props.history.push(url);
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.mainGrid}>
        <main>
          <Grid container spacing={4}>
            <Grid item xs={12} spacing={4}>
              <Paper className={classes.leaguesSection}>
                <div role="presentation">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      onClick={() => navigateToHome()}
                    >
                      Home
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      onClick={() => navigateToMyLeagues()}
                    >
                      My Leagues
                    </Link>
                    <Link underline="hover" color="primary" aria-current="page">
                      Manage Leagues
                    </Link>
                  </Breadcrumbs>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <JumboButton
                title={`Create League`}
                description=""
                image="https://source.unsplash.com/ghxL3qOfkPo"
                imgText="main image description"
                onClick={() => openCreate(true)}
              />
              <CreateLeagueDialogContainer
                isCreateOpen={isCreateOpen}
                openCreate={openCreate}
                setShowLoader={props.setShowLoader}
              />

            </Grid>

            <Grid item xs={12} >
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  // variant="fullWidth"
                  // className={classes.}
                  centered
                // aria-label="full width tabs example"
                >
                  <Tab label="Upcoming" {...a11yProps(0)} />
                  <Tab label="Live" {...a11yProps(1)} />
                  {/* <Tab label="Completed" {...a11yProps(2)} /> */}
                </Tabs>
              </AppBar>
            </Grid>
            <div className={classes.root}>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {!isEmpty(allTournament) ? (
                  map(allTournament, (tournament) => (
                    <>
                      {tournament.tournamentStatus === 'Upcoming' ?
                        <ManageLeagueTabs
                          key={tournament.tournament}
                          tournament={tournament}
                          isAuthenticated={false}
                          userHasAuthenticated={function (
                            isAuthenticated: boolean
                          ): void {
                            throw new Error("Function not implemented.");
                          }}
                          isSidebarOpen={false}
                          toggleSidebar={function (): void {
                            throw new Error("Function not implemented.");
                          }}
                          history={undefined}
                        />
                        : null}
                    </>
                  ))) : (
                  <Grid item xs={12}>
                    <CircularProgress color="primary" />
                  </Grid>
                )}
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {!isEmpty(allTournament) ? (
                  map(allTournament, (tournament) => (
                    <>
                      {tournament.tournamentStatus === 'Live' ?
                        <ManageLeagueTabs
                          key={tournament.tournament}
                          tournament={tournament}
                          isAuthenticated={false}
                          userHasAuthenticated={function (
                            isAuthenticated: boolean
                          ): void {
                            throw new Error("Function not implemented.");
                          }}
                          isSidebarOpen={false}
                          toggleSidebar={function (): void {
                            throw new Error("Function not implemented.");
                          }}
                          history={undefined}
                        />
                        : null}
                    </>
                  ))) : (
                  <Grid item xs={12}>
                  <CircularProgress color="primary" />
                  </Grid>
                )}
              </TabPanel>
            </SwipeableViews>
            </div>
          </Grid>

          <ManageLeagueDialog
            open={isManageOpen}
            league={selectedLeague}
            handleClose={() => openManage(false)}
          />
        </main>
      </Container>
    </React.Fragment>
  );
}
