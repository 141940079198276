import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Transition from "./Transition";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { LEAGUE_ACTIONS } from "../../../../reducers/LeagueReducer";
import { reducers } from "../../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, map, sortBy, reverse, get } from "lodash-es";
import { ILeague } from "../../../../api/LeagueAPI";

interface ICreateDialogProps<T> {
  type: any;
  open: boolean;
  // tournament :any;
  formFields: T;
  helperTexts: { [key: string]: string };
  setFormField: React.Dispatch<React.SetStateAction<T>>;
  fields: Array<{
    id: string;
    label: string;
  }>;
  handleClose: () => void;
  handleSubmit: (tournamnet: string, leagueType: string) => void;
  leagueTypeNullError: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radio: {
      "&$checked": {
        color: "#66bb6a",
      },
    },
    redioStyle: {
      paddingTop: 10,
    },
    buttonJustify: {
      justifyContent: "space-between",
    },
    formControl: {
      paddingTop: 10,
    },
    checked: {},
  })
);

export default function CreateLeagueDialogTournament<T>(
  props: ICreateDialogProps<T>
) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    type: { tournament },
  } = props;
  // let tournamenttype = '';
  // if(props.type) tournamenttype = props.type.type;
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [leagueType, setLeagueType] = useState("");
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [, setAllTournament] = useState([] as ILeague[]);
  const dispatch = useDispatch();
  const [leagueTypeValue,setleagueTypeValue ] = useState('both');
  
  useEffect(()=>{
    setleagueTypeValue(props.type.type)
    setLeagueType(props.type.type?.toLowerCase())
  },[props.type])
  
  

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLeagueType(e.target.value);

    props.setFormField({
      ...props.formFields,
      leagueType: e.target.value,
    });
  };

  useEffect(() => {
    const getAllTournament = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_TOURNAMENT });
    };
    getAllTournament();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  // store watcher
  useEffect(() => {
    const updateAllTournament = async () => {
      const tournaments = await store.all_tournament;
      const sortedAllTournamnet = reverse(
        sortBy(get(tournaments, "result.Items"), (l) => l.created || "")
      );
      setAllTournament(sortedAllTournamnet);
    };
    updateAllTournament();
  }, [store.all_tournament]);

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        fullScreen={fullScreen}
      >
        <DialogTitle id="form-dialog-title">Create a League</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            To Create a new private {tournament} league, please enter the
            following information.
          </DialogContentText>
          {map(props.fields, ({ id, label }, index) => (
            <TextField
              key={id}
              autoFocus={index === 0}
              required={index === 0}
              margin="dense"
              id={id}
              label={label}
              onChange={(e) =>
                props.setFormField({
                  ...props.formFields,
                  [id]: e.target.value,
                })
              }
              helperText={props.helperTexts[id]}
              error={!isEmpty(props.helperTexts[id])}
              fullWidth
            />
          ))}
          <Grid item container xs={12}>
            <FormControl className={classes.redioStyle}>
              <FormLabel>Select League Type</FormLabel>
              <RadioGroup
                row
                aria-label="leagueType"
                name="leagueType"
                id={leagueType}
                value={leagueType}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="both"
                  id="both"
                  name="both"
                  disabled={leagueTypeValue !== "Both"}
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="Both League"
                />
                <FormControlLabel
                  value="team"
                  id="team"
                  name="team"
                  disabled={ (leagueTypeValue !== "Both" && leagueTypeValue !== "Team" )}
                  control={<Radio color="primary" />}
                  label="Team League"
                  checked={leagueType === "team"}
                />
                <FormControlLabel
                  value="player"
                  id="player"
                  name="player"
                  control={<Radio color="primary" />}
                  label="Player League"
                  disabled={ (leagueTypeValue !== "Both" && leagueTypeValue !== "Player" )}
                  checked={leagueType === "player"}
                />
              </RadioGroup>
              {props.leagueTypeNullError === true ? (
                <div style={{ color: "red" }}>League Type required</div>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.buttonJustify}>
          <Button
            variant="outlined"
            onClick={props.handleClose}
            color="default"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => props.handleSubmit(tournament, leagueType)}
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
