import { API, Auth  } from "aws-amplify";

export interface IScoreResult {
  result: {
    Item: ITournament;
  };
}

export interface IScorePlayerResult {
  result: {
    Item: ITournamentPlayerList;
  };
}

export interface IPlayerLeaguesResult {
  result: {
    Item: IPlayerLeagues;
  };
}

export interface IPlayerLeaguesAsUserResult {
  result: {
    Item: IPlayerLeaguesAsUser;
  };
}

export interface ITotalScoreResult {
  result: {
    Item: ITournamentScore;
  };
}

export interface ITournamentStatsResult {
  result: {
    Item: ITournamentStats;
  };
}

export interface MatchStat extends IMatch {
  left: string;
  leftCount: number;
  leftConfidence: number;
  right: string;
  rightCount: number;
  rightConfidence: number;
  start: string;
  end: string;
  completed: boolean;
}

export interface ITournamentStats {
  tournament: string;
  stats: MatchStat[];
}

export interface ITournament {
  tournament: string;
  leagues: ILeagueScore[];
}

export interface ITournamentPlayerList {
  tournament: string;
  leagues: ILeagueScorePlayer[];
}

export interface IPlayerLeagues {
  leagueType: string;
  tournamentLeague: string;
}

export interface IPlayerLeaguesAsUser {
  leagueType: string;
  tournamentLeague: string;
  userId: string;
}

export interface ITournamentScore {
  tournament: string;
  scores: IUserScore[];
}

export interface ILeagueScore {
  leagueName: string;
  scores: IUserScore[];
}

export interface ILeagueScorePlayer {
  leagueName: string;
  scores: IUserScorePlayer[];
}

export interface IUserScore {
  userId: string;
  tournamentLeague: string;
  freeHits: Array<{
    expiry: string;
    match: number;
  }>;
  strikes: number;
  username: string;
  leagueType: string;
  confidenceScore: number;
  remainingPoints: number;
  lostMatches: number[];
  survivorRank: number;
  confidenceRank: number;
  usedPowerPlayPoints: number;
  usedFreeHits: number[];
}

export interface IUserScorePlayer {
  userId: string;
  totalScore: number;
  tournamentLeague: string;
  leagueType: string;
  freeHits: Array<{
    expiry: string;
    match: number;
  }>;
  strikes: number;
  username: string;
  confidenceScore: number;
  remainingPoints: number;
  lostMatches: number[];
  survivorRank: number;
  confidenceRank: number;
  usedPowerPlayPoints: number;
  usedFreeHits: number[];
}

export interface IGameSchedule {
  tournament: string;
  modified: string;
  schedule: IMatch[];
}

export interface IMatch {
  start: string;
  mom: string;
  right: string;
  left: string;
  completed: boolean;
  winner: string;
  end: string;
}

export interface IPlayerMatch {
  start: string;
  mom: string;
  right: string;
  left: string;
  completed: boolean;
  winner: string;
  end: string;
  bat1: string;
  bat2: string;
  bat3: string;
  bowl1: string;
  bowl2: string;
  bowl3: string;
  allrounder: string;
}

export interface IPlayerMatchUserID {
  userId: string;
}

export interface IGameScheduleResult {
  result: {
    Item: IGameSchedule;
  };
}

export interface IPlayerGameScoreBoard {
  [x: string]: any;
  match_no: number;
  total_runs: any;
  total_balls: string;
  total_wickets: string;
}

export interface IPlayerGameScoreBoardResult {
  result: {
    Item: IPlayerGameScoreBoard;
  };
}


export interface IPlayerGetScoreBoardResult {
  result: {
    Item: IPlayerScoreBoard;
  };
}

export interface IPlayerScoreBoard {
  total_runs: string;
  players_score: string[];
}

export interface IPlayerScorePrediction {
  [x: string]: any;
  total_runs: number;
  total_balls: number;
  total_wickets: number;
  players_score: {};
}

export interface IPlayerPredictionResult {
  result: {
    Item: IPlayerScorePrediction;
  };
}

export interface ITeamScore {
  [x: string]: any;
  Team: string;
  PlayerName: string;
  batter_runs: number;
  batter_balls: number;
  batter_status: number;
  batter_factor: string;
  bowler_runs: number;
  bowler_balls: number;
  bowler_wickets: number;
  bowler_factor: number;
}

export interface ITeamScoreBoard{
  [x: string]: any;
  total_runs: string;
  total_balls: string;
  total_wickets: string;
}

export interface UserAllTournamnetPoint {
  result: {
    Item: TotalScore;
  };
}

export interface TotalScore {
  totalScore: number;
  tournament: string;
  leagueName: string;
  userId: string;
  message: string;
  matches: IMatchSchedule[];
}

export interface IMatchSchedule {
  match: {
    left : string;
    right : string;
    TotalPlayerScore: number;
  },
  playerScores : Array<{
    player: string;
    playerName: string;
    score: number;
  }>;
}

export interface ITournamentScheduleResult {
  result: {
    Item: ITournamentSchedule;
  };
}

export interface ITournamentSchedule {
  schedule: string[];
}

export interface ITournamentPlayerResult {
  result: {
    Item: ITournamentSchedule;
  };
}

export interface ITournamentPlayer {
  tournament: string;
  team: string;
  players: string[];
}


const getStats = async (
  tournament: string
): Promise<ITournamentStatsResult> => {
  return API.get("tru-fan", `/get-tournament-stats/${tournament}`, {
    headers: {},
  });
};

const getTotalScores = async (
  tournament: string
): Promise<ITotalScoreResult> => {
  return API.get("tru-fan", `/get-total-scores/${tournament}`, {
    headers: {},
  });
};

const getScores = async (tournament: string): Promise<IScoreResult> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.get(
    "tru-fan",
    `/get-scores/${tournament}/${user.attributes.email}`,
    {
      headers: {},
    }
  );
};

const getUserLeagueByLeageType = async (tournament: string, leagueType: string): Promise<IScorePlayerResult> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.get(
    "tru-fan",
    `/get-user-leagues-by-league-type/${tournament}/${leagueType}/${user.attributes.email}`,
    {
      headers: {},
    }
  );
};

const getSchedule = async (
  tournament: string
): Promise<IGameScheduleResult> => {
  return API.get("tru-fan", `/get-schedule/${tournament}`, {
    headers: {},
  });
};

const updateSchedule = async (
  tournament: string,
  schedule: IMatch[]
): Promise<IGameScheduleResult> => {
  return API.post("tru-fan", `/update-schedule/${tournament}`, {
    body: { schedule },
  });
};

const teamScoreBoardUpdater = async (
  tournament : string,
  payload: IPlayerGameScoreBoard,
): Promise<IPlayerGameScoreBoardResult> => {
  return API.post("tru-fan", `/update-team-score-board/${tournament}`, {
    body: { payload },
  });
};

const setPlayerScoreBoardPrediction = async (
  tournament: string,
  match_no: number,
  payload: IPlayerScorePrediction,
): Promise<IPlayerPredictionResult> => {
  return API.post(
    "tru-fan",
    `/update-player-score-board/${tournament}/${match_no}`,
    {
      body: { total_runs : payload.total_runs, total_balls : payload.total_balls, total_wickets : payload.total_wickets, players_score: payload.players_score },
    }
  );
};

const getTeamScoreBoard = async (
  tournament: string,
  match_no: number,
): Promise<IPlayerGetScoreBoardResult> => {
  return API.get("tru-fan", `/get-team-score-board/${tournament}/${match_no}`, {
    headers: {},
  });
};

const scheduleUploader = async (
  state : any,
  payload: any,
) => {
  return API.post("tru-fan", `/upload-schedule/${state?.tournament}`, {
    body: { payload ,state },
  });
};

const playerUploader = async (
  tournament : string,
  payload: any,
): Promise<ITournamentPlayerResult> => {
  return API.post("tru-fan", `/upload-players/${tournament}`, {
    body: { payload },
  });
};

const getUserLeagueByLeageTypeTwo = async (tournament: string, leagueTypeTwo: string): Promise<IScorePlayerResult> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.get(
    "tru-fan",
    `/get-user-leagues-by-league-type/${tournament}/${leagueTypeTwo}/${user.attributes.email}`,
    {
      headers: {},
    }
  );
};

const userAllTournamnetPoints = async (tournament: string, leagueName: string, userId: string): Promise<UserAllTournamnetPoint> => {
  return API.get(
    "tru-fan",
    `/user-all-tournament-points/${tournament}/${leagueName}/${userId}`,
    {
      headers: {},
    }
  );
};

const userAllTournamnetPointsTodayMatch = async (tournament: string, leagueName: string, userId: string): Promise<UserAllTournamnetPoint> => {
  return API.get(
    "tru-fan",
    `/user-all-tournament-points-today-match/${tournament}/${leagueName}/${userId}`,
    {
      headers: {},
    }
  );
};

const updateTounamnetStatus = async(tournament: string, status: string) => {

  console.log(tournament,status);
  return API.get(
    "tru-fan",
    `/update-tounamnet-status/${tournament}/${status}`,
    {
      headers: {},
    }
  );


};

export default {
  getStats,
  getScores,
  getUserLeagueByLeageType,
  getTotalScores,
  getSchedule,
  updateSchedule,
  teamScoreBoardUpdater,
  setPlayerScoreBoardPrediction,
  getTeamScoreBoard,
  scheduleUploader,
  playerUploader,
  getUserLeagueByLeageTypeTwo,
  userAllTournamnetPoints,
  userAllTournamnetPointsTodayMatch,
  updateTounamnetStatus
};
