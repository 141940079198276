import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { URL } from "../../Routes";
import { Link as RouterLink } from "react-router-dom";
import { Container, Grid, Link } from "@material-ui/core";
import Intro from "./modules/components/Intro";
// import JumboButton from "./modules/components/JumboButton";
import { reducers } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import {  sortBy, reverse, get } from "lodash-es";
import { ILeague } from "../../api/LeagueAPI";
import { LEAGUE_ACTIONS } from "../../reducers/LeagueReducer";
// import { LOADER_ACTIONS } from "../../reducers/LoaderReducer";
import TournamnetView from "./TouranamentListSwapableView";


interface IDashboardProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  admin: boolean;
  history: any;
}

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  tournamentScores: {
    padding: 0,
  },
}));

export default function MyTournament(props: IDashboardProps) {
  if (!props.isAuthenticated) props.history.push(URL.HOME);
  // if (props.admin)
  //   props.history.push(
  //     URL.LEAGUES.SCHEDULE_EDITOR.replace(":game", CURRENT_TOURNAMENT)
  //   );
  const classes = useStyles();
  const dispatch = useDispatch();
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [allTournament, setAllTournament] = useState([] as ILeague[]);
 
  // setTimeout(() => {
  //   console.log('ok');
  //     dispatch({type:LOADER_ACTIONS.SHOW_LOADER})
  // }, 3000);


  useEffect(() => {
    const getAllTournament = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_TOURNAMENT});
    };
    getAllTournament();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  // store watcher
  useEffect(() => {
    const updateAllTournament = async () => {
      const tournaments = await store.all_tournament;
      const sortedAllTournamnet= reverse(sortBy(get(tournaments, "result.Items"), (l) => l.created || ""));    
       setAllTournament(sortedAllTournamnet);
    };
    updateAllTournament();
  }, [store.all_tournament]);

  const navigateToLeague = (tournament: string,type: string) => {
    const url = URL.LEAGUES.VIEW.replace(":game", tournament).replace(":type",type);
    props.history.push(url);
  };


  return (
    <Container maxWidth="lg" className={classes.mainGrid}>
      <Intro
        title={
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            component={RouterLink}
            to={URL.LEAGUES.HOME}
          >
          All tournaments 
          </Link>
        }
        description=""
        image="https://source.unsplash.com/hY3sn--SgwM"
        imgText="my leagues"
        linkText=""
      />
       <Grid
      >
        <TournamnetView Data={allTournament} navigateToLeague={navigateToLeague} />

        {/* {!isEmpty(allTournament) && (
          <Grid>
              {map(allTournament, (tournament) => (
               <Grid
                container
                style={{ marginTop: 10 }}
                alignItems="center"
                justify="center"
                key={tournament.tournament}
              >
                <JumboButton
                  title={tournament.tournament}
                  description=""
                  image="https://source.unsplash.com/ghxL3qOfkPo"
                  imgText="main image description"
                  // onClick={() => naviPage(
                  //   tournament.tournament
                  // )}
                  
                  onClick={() =>
                    // {setSelectedLeague(tournament);
                    // dispatch({
                    //   type: LEAGUE_ACTIONS.GET_LEAGUE_MEMBERS,
                    //   tournament: tournament.tournament,
                    // });
                    navigateToLeague(
                      tournament.tournament
                    )}
                  // }
                />
              </Grid>
            )) }
         
          </Grid> 
   ) }
          */}
     
        </Grid>
    </Container>
  );
}
