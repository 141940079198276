import { combineReducers } from "redux";
import LeagueReducer from "./LeagueReducer";
// import loaderReducer from "./LoaderReducer";

const inboudReducers = {
  LeagueReducer
};

export type reducers = typeof inboudReducers;

export default combineReducers(inboudReducers);
