import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Intro from "../components/Intro";
import { URL } from "../../../../Routes";
import {
  isEmpty,
  map,
  zipWith,
  set,
  ceil,
  groupBy,
  omit,
  get,
  min,
  filter,
  cloneDeep,
  times,
  includes,
  findIndex,
} from "lodash-es";
import LeagueAPI, {
  IPlayerPrediction,
  ISurvivorPlayerPredictionPayload,
} from "../../../../api/LeagueAPI";
import { LEAGUE_ACTIONS } from "../../../../reducers/LeagueReducer";
import { useDispatch, useSelector } from "react-redux";
import { MatchStatus } from "../components/TeamSwitcher";
import { reducers } from "../../../../reducers";
import { Card, CardHeader, Divider, CardContent, Fab } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SurvivorStatusBarPlayer from "../components/SurvivorPlayerStatusBar";
import {
  differenceInMilliseconds,
  subHours,
  addHours,
  isAfter,
} from "date-fns";
import {
  IUserScore,
  IPlayerMatch,
  MatchStat,
} from "../../../../api/DashboardAPI";
import SwipeableViews from "react-swipeable-views";
import TeamSwitcherPlayer from "../components/TeamSwitcherPlayer";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";

const listNotFound = [
  {
    playerName: "Not Found",
    team: "IND",
    count: 0,
  },
  {
    playerName: "Not Found",
    team: "IND",
    count: 0,
  },
  {
    playerName: "Not Found",
    team: "IND",
    count: 0,
  },
  {
    playerName: "Not Found",
    team: "IND",
    count: 0,
  },
  {
    playerName: "Not Found",
    team: "IND",
    count: 0,
  },
];
const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(13),
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: 0,
    },
  },
  leaguesSection: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  saveFab: {
    margin: 0,
    top: "auto",
    right: theme.spacing(35),
    bottom: theme.spacing(5),
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
    left: "auto",
    position: "fixed",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  scoreInsights: {
    marginBottom: theme.spacing(2),
  },
  playerPaper: {
    paddingTop: "10px",
    paddingBottom: "20px",
  },
  prictplayerTitle: {
    // color:'#fff',
    // fontSize:'20px'
    fontSize: "20px",
    marginBottom: "10px",
  },
  playerPaper2: {
    minWidth: "200px",
    margin: "5px",
    display: "block",
  },
  playerChip: {
    color: "#0000009a",
    marginRight: "100px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  CardSet: {
    padding: "5px",
  },
  PointColor: {
    color: "#FFF",
  },
}));

export interface IConfidenceScore {
  score: number;
  remaining: number;
}

export interface IUserMatch {
  match: IPlayerMatch;
  prediction: IPlayerPrediction;
}

interface ISurvivorProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  history: any;
  setShowLoader: any;
  match: {
    params: { [key: string]: string };
  };
}

export interface IPowerPlayPoints {
  remaining: number;
  total: number;
  freeHits: IUserScore["freeHits"];
  usedFreeHits: IUserScore["usedFreeHits"];
}

export interface IEditPredictionPayload {
  open: boolean;
  index: number;
  matchStatus: MatchStatus;
}

export const getValidFreeHits = (
  freeHits: IPowerPlayPoints["freeHits"],
  usedFreeHits: IPowerPlayPoints["usedFreeHits"]
): IPowerPlayPoints["freeHits"] => {
  if (isEmpty(freeHits)) {
    return [];
  }
  const validFreeHits = filter(freeHits, ({ expiry, match }) => {
    return (
      isAfter(new Date(expiry), new Date()) && !includes(usedFreeHits, match)
    );
  });
  return validFreeHits;
};

export const getFreeHitExpiry = (
  freeHits: IPowerPlayPoints["freeHits"],
  usedFreeHits: IPowerPlayPoints["usedFreeHits"]
): Date => {
  const validFreeHits = getValidFreeHits(freeHits, usedFreeHits);
  return new Date(validFreeHits[0].expiry);
};

export default function Survivor(props: ISurvivorProps) {
  if (!props.isAuthenticated) props.history.push(URL.HOME);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [schedule, setSchedule] = useState([] as IPlayerMatch[]);
  const [userMatches, setUserMatches] = useState([] as IUserMatch[]);
  const [confidenceScores, setConfidenceScores] = useState([
    { score: 20, remaining: 0 },
    { score: 40, remaining: 0 },
    { score: 60, remaining: 0 },
    { score: 80, remaining: 0 },
    { score: 100, remaining: 0 },
  ] as IConfidenceScore[]);
  const [, setUserScore] = useState({} as IUserScore);
  const [powerPlayPoints, setPowerPlayPoints] = useState({
    remaining: 0,
    total: 0,
    freeHits: [],
    usedFreeHits: [],
  } as IPowerPlayPoints);
  const [, openEditPrediction] = useState({
    open: false,
    index: 0,
    matchStatus: MatchStatus.NOT_STARTED,
  } as IEditPredictionPayload);
  const [matchIndex, setMatchIndex] = useState(0);
  const [top5player, settop5PlatersList] = useState([[]]);
  const [matchStats, setMatchStats] = useState([] as MatchStat[]);

  const tournament = props.match.params.game;
  const leagueName = props.match.params.league;

  // constructor and destructor
  useEffect(() => {
    const init = async () => {
      const getScore = async (): Promise<IUserScore> => {
        try {
          const scoreResponse = await LeagueAPI.getScore(
            tournament,
            leagueName
          );
          const {
            usedPowerPlayPoints,
            freeHits,
            usedFreeHits,
          } = scoreResponse.result.Item.leagues[0].scores[0];
          setUserScore(scoreResponse.result.Item.leagues[0].scores[0]);
          return {
            usedPowerPlayPoints,
            freeHits,
            usedFreeHits: usedFreeHits || [],
          } as IUserScore;
        } catch (e) {
          return {} as IUserScore;
        }
      };

      const getStats = async () => {
        try {
          const topPlayers = await LeagueAPI.getTop5PridictedPlayersCountByLeague(
            tournament,
            leagueName
          );
          const statsResponse = await LeagueAPI.getLeagueStats(
            tournament,
            leagueName
          );
          console.log(topPlayers);
          if (topPlayers.length >= 1) {
            settop5PlatersList(topPlayers);
          } else if (topPlayers.length === 0) {
            // settop5PlatersList(listNotFound);
          }

          // settop5PlatersList(topPlayers);
          const stats = statsResponse.result.Item.stats;
          setMatchStats(stats);
          const matchIdx = findIndex(stats, (stat) => stat.completed === false);
          setMatchIndex(matchIdx < 0 ? 0 : matchIdx);
        } catch (e) {
          return [];
        }
      };

      dispatch({ type: LEAGUE_ACTIONS.GET_SCHEDULE, tournament });
      dispatch({
        type: LEAGUE_ACTIONS.GET_SURVIVOR_PLAYER_PREDICTION_FOR_MATCH,
        tournament,
        leagueName,
      });
      const [leaguesQuery, scoreQuery] = await Promise.all([
        LeagueAPI.getLeague(leagueName),
        getScore(),
        getStats(),
      ]);
      const { totalPowerPlayPoints } = leaguesQuery.result.Item;
      const { usedPowerPlayPoints, freeHits, usedFreeHits } = scoreQuery;
      setPowerPlayPoints({
        remaining: totalPowerPlayPoints - (usedPowerPlayPoints || 0),
        total: totalPowerPlayPoints,
        freeHits,
        usedFreeHits,
      });
    };

    init();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leagueName, tournament]);

  // schedule store watcher
  useEffect(() => {
    const updateSchedule = async () => {
      const response = await store.schedule;
      response.result && setSchedule(response.result.Item.schedule);
    };
    updateSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.schedule]);

  // prediction watcher
  useEffect(() => {
    const updatePredictions = async () => {
      const response = await store.survivorPlayerPrediction;
      const playerpredictions = get(
        response,
        "result.Item.playerpredictions",
        times(schedule.length, () => ({
          bat1: "",
          bat2: "",
          bat3: "",
          bowl1: "",
          bowl2: "",
          bowl3: "",
          allrounder: "",
        }))
      );

      const maxUses = ceil(schedule.length / 5);
      const currentUses = omit(
        groupBy(playerpredictions, (p) => p.confidence),
        [0]
      );
      const currConfidenceScores = map(confidenceScores, (currScore) => {
        return {
          score: currScore.score,
          remaining: maxUses - get(currentUses, currScore.score, []).length,
        };
      });

      const currUserMatches = zipWith(
        schedule,
        playerpredictions,
        (match: IPlayerMatch, prediction: IPlayerPrediction) => {
          // auto assign for skipped matches. Think about moving to backend
          console.log(prediction);

          if (
            isEmpty(prediction?.team) &&
            (match.completed ||
              differenceInMilliseconds(
                subHours(new Date(match.start), 1),
                new Date()
              ) < 0)
          ) {
            const minimumScoreAssignable =
              min(
                map(
                  filter(
                    currConfidenceScores,
                    ({ score, remaining }) => remaining !== 0
                  ),
                  "score"
                )
              ) || 0;
            if (minimumScoreAssignable !== 0) {
              // edge case where no scores remainining to allocate
              currConfidenceScores[
                minimumScoreAssignable / 20 - 1
              ].remaining -= 1;
            }
            if (prediction?.confidence === 0) {
              // Only overrride if confidence was not set
              set(prediction, "confidence", minimumScoreAssignable);
            }
          }

          if (isEmpty(match.end)) {
            // FIXME: remove this when schedule is set
            match.end = addHours(new Date(match.start), 4).toISOString();
          }
          return { match, prediction };
        }
      );
      updateUserMatches(currUserMatches);
    };

    if (!isEmpty(schedule)) {
      updatePredictions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule, store.survivorPlayerPrediction]);

  const updateUserMatches = (userSchedule: IUserMatch[]) => {
    const maxUses = ceil(userSchedule.length / 5);
    const currentUses = omit(
      groupBy(userSchedule, (s) => s.prediction.confidence),
      [0]
    );
    const currConfidenceScores = map(confidenceScores, (currScore) => {
      return {
        score: currScore.score,
        remaining: maxUses - get(currentUses, currScore.score, []).length,
      };
    });
    setUserMatches(userSchedule);
    setConfidenceScores(currConfidenceScores);
  };

  const updatePredictionHandler = (
    index: number,
    prediction: IPlayerPrediction
  ) => {
    const currUserSchedule = cloneDeep(userMatches);
    set(currUserSchedule, [index, "prediction"], prediction);
    updateUserMatches(currUserSchedule);
    return currUserSchedule;
  };

  const _savePlayerPredictionToDb = useCallback(
    (payload: ISurvivorPlayerPredictionPayload) => {
      props.setShowLoader(true);
      LeagueAPI.setSurvivorPlayerPrediction(payload)
        .then(() => {
          props.setShowLoader(false);
          enqueueSnackbar("Changed Saved", {
            variant: "success",
          });
        })
        .catch(() => {
          props.setShowLoader(false);

          enqueueSnackbar("Oops! Something went wrong", {
            variant: "error",
          });
        });
    },
    [enqueueSnackbar, props]
  );

  const _savePlayerPredictions = () => {
    const payload = {
      tournament,
      leagueName,
      playerpredictions: map(userMatches, "prediction"),
    };

    _savePlayerPredictionToDb(payload);
  };

  const _openUnlockPredictionHandler = (
    index: number,
    matchStatus: MatchStatus
  ) => {
    openEditPrediction({ open: true, index, matchStatus });
  };

  const minimumScoreAssignable =
    min(
      map(
        filter(confidenceScores, ({ score, remaining }) => remaining !== 0),
        "score"
      )
    ) || 0;

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.mainGrid}>
        <main>
          <Intro
            title={tournament}
            description=""
            image="https://source.unsplash.com/bY4cqxp7vos"
            imgText="main image description"
            linkText=""
          />
          <SurvivorStatusBarPlayer save={_savePlayerPredictions} />
          <Card elevation={3} className={classes.scoreInsights}>
            <CardHeader title="Most Selected Players" />
            <Divider />
            <CardContent>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Box fontWeight="fontWeightBold" fontSize="h4.fontSize">
                  Match #{matchIndex + 1}
                </Box>{" "}
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Box fontSize="h5.fontSize">
                  {matchStats.find((el) => el.left)
                    ? `${matchStats?.[matchIndex]?.left} VS ${matchStats?.[matchIndex]?.right}`
                    : null}
                </Box>
              </Grid>

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <ChevronLeftIcon
                  onClick={() =>
                    matchIndex > 0 && setMatchIndex(matchIndex - 1)
                  }
                />

                <Grid
                  item
                  style={{
                    display: "inline-flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >

                  {top5player[matchIndex] !== undefined ?
                    top5player[matchIndex].length >= 1 ?

                      top5player[matchIndex].map((ele: any, i) => {
                        return (
                          <React.Fragment>
                            <div className={classes.CardSet} key={i}>
                              <Chip
                                avatar={
                                  <Avatar className={classes.PointColor}>
                                    #{i + 1}
                                  </Avatar>
                                }
                                label={
                                  <span>
                                    <h4>
                                      {ele.playerName} - {ele.count}
                                    </h4>
                                  </span>
                                }
                                variant="outlined"
                                color="primary"
                              />
                            </div>
                          </React.Fragment>
                        );
                      }) :

                      listNotFound.map((ele: any, i) => {
                        return (
                          <React.Fragment>
                            <div className={classes.CardSet} key={i}>
                              <Chip
                                avatar={
                                  <Avatar className={classes.PointColor}>
                                    #{i + 1}
                                  </Avatar>
                                }
                                label={
                                  <span>
                                    <h4>
                                      {ele.playerName} - {ele.count}
                                    </h4>
                                  </span>
                                }
                                variant="outlined"
                                color="primary"
                              />
                            </div>
                          </React.Fragment>
                        );
                      })
                    : "not found"
                  }

                </Grid>
                <ChevronRightIcon
                  onClick={() =>
                    matchIndex < userMatches.length - 1 && matchIndex < top5player.length - 1 &&
                    setMatchIndex(matchIndex + 1)
                  }
                />
              </Grid>
            </CardContent>
          </Card>
          {/* top */}
          <Card elevation={3} className={classes.scoreInsights}>
            <CardHeader title="Player Predictions" />
            <Divider />
            <CardContent>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={1}>
                  <ChevronLeftIcon
                    onClick={() =>
                      matchIndex > 0 && setMatchIndex(matchIndex - 1)
                    }
                  />
                </Grid>
                <Grid item xs={10}>
                  <SwipeableViews
                    enableMouseEvents
                    index={matchIndex}
                    onChangeIndex={(index) => setMatchIndex(index)}
                  >
                    {map(userMatches, (userMatch, index) => (
                      <Grid
                        item
                        xs={12}
                        key={`${userMatch.match.left}vs${userMatch.match.right}@${userMatch.match.start}`}
                      >
                        <TeamSwitcherPlayer
                          userMatch={userMatch}
                          tournament={tournament}
                          index={index}
                          confidenceScores={confidenceScores}
                          minimumScoreAssignable={minimumScoreAssignable}
                          powerPlayPoints={powerPlayPoints}
                          updatePredictionHandler={updatePredictionHandler}
                          isEditMode={false}
                          save={_savePlayerPredictions}
                          edit={_openUnlockPredictionHandler}
                        />
                      </Grid>
                    ))}
                  </SwipeableViews>
                </Grid>
                <Grid item xs={1}>
                  <ChevronRightIcon
                    onClick={() =>
                      matchIndex < userMatches.length - 1 && matchIndex < top5player.length - 1 &&
                      setMatchIndex(matchIndex + 1)
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.saveFab}>
              <Fab
                color="primary"
                aria-label="save"
                variant="extended"
                onClick={_savePlayerPredictions}
              >
                <SaveIcon className={classes.extendedIcon} />
                Save
              </Fab>
            </Grid>
          </Grid>
        </main>
      </Container>
    </React.Fragment>
  );
}
