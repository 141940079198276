import React from "react";
import JumboButton from "./modules/components/JumboButton";
import { Grid } from "@material-ui/core";
import { URL } from "../../Routes";
import { useHistory } from "react-router-dom";

export default function ManageTournament() {
  let history = useHistory();

  const navigateToLeague = () => {
    const url = URL.LEAGUES.MANAGE;
    history.push(url);
  };

  return (
    <>
      <Grid
        container
        style={{ marginTop: 10 }}
        alignItems="center"
        justify="center"
      >
        <JumboButton
          title={""}
          description=""
          image="https://source.unsplash.com/ghxL3qOfkPo"
          imgText="main image description"
          onClick={() => navigateToLeague()}
        />
      </Grid>
    </>
  );
}
