import React from "react";
import clsx from "clsx";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      position: "relative",
      display: "flex",
      alignItems: "center",
      height: "85vh",
      [theme.breakpoints.up("sm")]: {
        height: "90vh",
        minHeight: 500,
        maxHeight: 1300,
      },
    },
    container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(14),
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    backdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.5,
      zIndex: -1,
    },
    background: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      zIndex: -2,
    },
    arrowDown: {
      position: "absolute",
      bottom: theme.spacing(4),
    },
  });

interface IProductHeroLayoutProps {
  backgroundClassName: string;
  children: any;
  classes: any;
}

function ProductHeroLayout(props: IProductHeroLayoutProps) {
  const { backgroundClassName, children, classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        {children}
        {/* <div className={classes.backdrop} /> */}
        <div className={clsx(classes.background, backgroundClassName)} />
      </Container>
    </section>
  );
}

export default withStyles(styles)(ProductHeroLayout);
