import React from "react";
import { Route, Switch } from "react-router-dom";
import Index from "./components/landing/Index";
import SignUp from "./components/landing/SignUp";
import SignIn from "./components/landing/SignIn";
import HowToPlay from "./components/landing/HowToPlay";
import FAQs from "./components/landing/FAQs";
import ResetPassword from "./components/landing/ResetPassword";
import MyLeagues from "./components/dashboard/MyLeagues";
import ManageTournament from "./components/dashboard/ManageTournament";
import Insights from "./components/dashboard/Home";
import CreateLeague from "./components/dashboard/CreateLeague";
import ManageLeagues from "./components/dashboard/ManageLeagues";
import MyTournament from "./components/dashboard/MyTournament";
import TournamentEdit from "./components/dashboard/TournamentEdit";
import IPL from "./components/dashboard/modules/views/IPL";
import Survivor from "./components/dashboard/modules/views/SurvivorConfidence";
import ScheduleEditor from "./components/dashboard/modules/views/ScheduleEditor";
import ContactUS from "./components/landing/ContactUS";
import Leag from "./components/landing/Leag";
import JoinLeagues from "./components/dashboard/modules/components/JoinLeagues";
import Leagjoin from "./components/dashboard/modules/components/Leagjoin";
import SurvivorPlayer from "./components/dashboard/modules/views/SurvivorConfidencePlayer";
import TournamentSummary from "./components/dashboard/TournamnetSummary";
import ManageTournamentMatch from "./components/dashboard/ManageTournamentMatch"
import ScheduleEditorPlayer from "./components/dashboard/modules/views/ScheduleEditorPlayer";
import CreateTournament from "./components/dashboard/CreateTournament"
import CreatePlayerList from "./components/dashboard/CreatePlayerList"

const AppliedRoute = ({ component: C, appProps, ...rest }) => {
  return <Route {...rest} render={(props) => <C {...props} {...appProps} />} />;
};

const NotFound = () => {
  return <div>You are not supposed to be here!</div>;
};
export const URL = {
  HOME: "/",
  SIGNUP: "/sign-up",
  SIGNIN: "/sign-in",
  FAQ: "/faq",
  HOW_TO_PLAY: "/how-to-play",
  CONTACT_US: "/contact",
  LEAGUES: {
    HOME: "/tournaments",
    VIEW: "/leagues/tornament/:game/:type",
    MY_TOURNAMENT: "/leagues/tournament",
    TOURNAMNET_SUMMARY: "/tournaments/:game/:type",
    JOINLEAGUES: "/tournament/:game",
    CREATE: "/leagues/create",
    JOIN: "/leagues/leag",
    MANAGE: "/leagues/manage",
    MANAGE_TOURNAMENT: "/manage/tournament",
    IPL: "/leagues/IPL",
    SURVIVOR: "/leagues/:game/:league/survivor",
    JOIN_LEAGUES_MODAL: "/leagues/:game/:league/League",
    SURVIVOR_PLAYER: "/leagues/:game/:league/survivorPlayer",
    SCHEDULE_EDITOR: "/leagues/:game/playereditor",
    SCHEDULE_EDITOR_PLAYER: "/leagues/:game/playereditor2",
    TOURNAMENT_EDIT: "/list/tournaments",
    MANAGETOURNAMENTMATCH: "/list/tournaments/:game/teameditor",
    CREATE_TOURNAMENT: "/create-tournament",
    CREATE_PLAYER_LIST: "/create-player-list"
  },
  FORGOT_PASSWORD: "/reset-password",
};

export default (props) => {
  return (
    <Switch>
     <AppliedRoute path={URL.HOME} exact component={Index} appProps={props} />
      <AppliedRoute
        path={URL.SIGNUP}
        exact
        component={SignUp}
        appProps={props}
      />
      <AppliedRoute
        path={URL.SIGNIN}
        exact
        component={SignIn}
        appProps={props}
      />
      <AppliedRoute
        path={URL.FORGOT_PASSWORD}
        exact
        component={ResetPassword}
        appProps={props}
      />
      <AppliedRoute path={URL.FAQ} exact component={FAQs} appProps={props} />
      <AppliedRoute
        path={URL.HOW_TO_PLAY}
        exact
        component={HowToPlay}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.HOME}
        exact
        component={Insights}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.VIEW}
        exact
        component={MyLeagues}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.MY_TOURNAMENT}
        exact
        component={MyTournament}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.JOINLEAGUES}
        exact
        component={JoinLeagues}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.CREATE}
        exact
        component={CreateLeague}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.MANAGE}
        exact
        component={ManageLeagues}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.MANAGE_TOURNAMENT}
        exact
        component={ManageTournament}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.IPL}
        exact
        component={IPL}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.SURVIVOR}
        exact
        component={Survivor}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.SURVIVOR_PLAYER}
        exact
        component={SurvivorPlayer}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.JOIN_LEAGUES_MODAL}
        exact
        component={Leagjoin}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.JOIN}
        exact
        component={Leag}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.SCHEDULE_EDITOR}
        exact
        component={ScheduleEditor}
        appProps={props}
      />
            <AppliedRoute
        path={URL.LEAGUES.SCHEDULE_EDITOR_PLAYER}
        exact
        component={ScheduleEditorPlayer}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.TOURNAMENT_EDIT}
        exact
        component={TournamentEdit}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.CREATE_TOURNAMENT}
        exact
        component={CreateTournament}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.CREATE_PLAYER_LIST}
        exact
        component={CreatePlayerList}
        appProps={props}
      />
      <AppliedRoute
        path={URL.LEAGUES.MANAGETOURNAMENTMATCH}
        exact
        component={ManageTournamentMatch}
        appProps={props}
      />
      <AppliedRoute
        path={URL.CONTACT_US}
        exact
        component={ContactUS}
        appProps={props}
      />
      <AppliedRoute
        path={URL.TOURNAMNET_SUMMARY}
        exact
        component={TournamentSummary}
        appProps={props}
      />
      <AppliedRoute
        path={URL.TOURNAMNET_EDIT}
        exact
        component={TournamentEdit}
        appProps={props}
      />
      <Route component={NotFound} />
    </Switch>
  );
};
