import { API, Auth } from "aws-amplify";
// import { number } from "yup";
import { IUserScore, IScoreResult, ITournamentStats, ITeamScore, IPlayerLeaguesAsUserResult } from "./DashboardAPI";

interface IAPIQueryResult {
  Count: number;
  ScannedCount: number;
}

export interface ILeague {
  type: string;
  userId: string;
  leagueName: string;
  description: string;
  password: string;
  leagueType: string;
  tournament: string;
  totalPowerPlayPoints: number;
  created?: string;
}
export interface ITournamnet {
  tournament: string;
}

interface ILeagueResult {
  result: {
    Item: ILeague;
  };
}

export interface IUserLeagues extends IAPIQueryResult {
  Items: Array<{
    adminLeagues: ILeague[];
    userLeagues: ILeague[];
    all_leagues: ILeague[];
  }>;
}

export interface IAllTournamnet extends IAPIQueryResult {
  Items: Array<{
    all_tournament: ITournamnet[];
  }>;
}

export interface IAllLeagues extends IAPIQueryResult {
  Items: Array<{
    all_leagues: ILeague[];
  }>;
}

export interface TournamentCreate {
  userId: string;
  tournamentname: string;
  sport: string;
  venue: string;
  noofmatch: string;
  created?: string;
}

export interface ILeagueStats extends ITournamentStats {
  league: string;
}

export interface ILeagueStatsResult {
  result: {
    Item: ILeagueStats;
  };
}

export interface IUserLeagueMembers extends IAPIQueryResult {
  Items: ILeagueMemberPayload[];
}

export interface IPlayerListResult {
  result: {
    Item: ITeamPlayers;
  };
}

export interface ILeagueMemberPayload {
  leagueName: string;
  userId: string;
  password: string;
}

export interface IPrediction {
  team: string;
  mom: string;
  confidence: number;
}

export interface IPlayerPrediction {
  bat1: string;
  bat2: string;
  bat3: string;
  bowl1: string;
  bowl2: string;
  bowl3: string;
  allrounder: string;
  team: string;
  mom: string;
  confidence: number;
}

export interface ITeamPlayers {
  tournament: string;
  team: string;
  players: string[];
}

export interface ISurvivorPredictionPayload {
  predictions: IPrediction[];
  tournament: string;
  leagueName: string;
  userId?: string;
}

export interface ISurvivorPlayerPredictionPayload {
  playerpredictions: IPlayerPrediction[];
  tournament: string;
  leagueName: string;
  userId?: string;
}

export interface ISurvivorPredictionResult {
  result: {
    Item: ISurvivorPredictionPayload;
  };
}

export interface ISurvivorPlayerPredictionResult {
  result: {
    Item: ISurvivorPlayerPredictionPayload;
  };
}

const createLeague = async (payload: ILeague): Promise<IUserLeagues> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.post("tru-fan", "/create-league", {
    body: {
      ...payload,
      userId: user.attributes.email,
      name: user.attributes.given_name,
    },
  });
};

const createTournament = async (
  payload: TournamentCreate
): Promise<IUserLeagues> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.post("tru-fan", "/create-tournament", {
    body: {
      ...payload,
      userId: user.attributes.email,
    },
  });
};

const getLeague = async (leagueName: string): Promise<ILeagueResult> => {
  return API.get("tru-fan", `/get-league/${leagueName}`, {
    headers: {},
  });
};

const getLeagueStats = async (
  tournament: string,
  league: string
): Promise<ILeagueStatsResult> => {
  return API.get("tru-fan", `/get-league-stats/${tournament}/${league}`, {
    headers: {},
  });
};

const getUserLeagues = async (): Promise<Array<ILeague>> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.get("tru-fan", `/get-leagues/${user.attributes.email}`, {
    headers: {},
  });
};

const getAllLeague = async (tournament: string): Promise<Array<ILeague>> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.get(
    "tru-fan",
    `/get-all-leagues/${tournament}/${user.attributes.email}`,
    {
      headers: {},
    }
  );
};


const getAllTournamentID = async (tournament: string): Promise<Array<ILeague>> => {
  return API.get(
    "tru-fan",
    `/get-all-leagues/${tournament}/`,
    {
      headers: {},
    }
  );
};

const getAllTournament = async (): Promise<Array<ILeague>> => {
  return API.get("tru-fan", `/get-all-tournament/`, {
    headers: {},
  });
};

const getLeagueMembers = async (
  tournament: string,
  leagueName: string
): Promise<IUserLeagueMembers> => {
  return API.get("tru-fan", `/get-league-members/${tournament}/${leagueName}`, {
    headers: {},
  });
};

const setLeagueMembers = async (
  tournament: string,
  leagueName: string,
  type : string,
  members: string[]
): Promise<IUserLeagueMembers> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.post(
    "tru-fan",
    `/set-league-members/${tournament}/${leagueName}`,
    {
      body: {
        type,
        members,
        user: {
          name: user.attributes.given_name,
          email: user.attributes.email,
        },
      },
    }
  );
};

const setJoinLeagueMembers = async (
  tournament: string,
  leagueName: string,
  password: string,
  type : string,
  members: string[]
): Promise<IUserLeagueMembers> => {
  const user = await Auth.currentAuthenticatedUser();
  members.push(user.attributes.email);
  return API.post(
    "tru-fan",
    `/set-league-members/${tournament}/${leagueName}`,
    {
      body: {
        password,
        type,
        members,
        user: {
          name: user.attributes.given_name,
          email: user.attributes.email,
        },
      },
    }
  );
};

const getSurvivorPrediction = async (
  tournament: string,
  leagueName: string
) => {
  const user = await Auth.currentAuthenticatedUser();
  return API.get(
    "tru-fan",
    `/get-survivor-prediction/${tournament}/${leagueName}/${user.attributes.email}`,
    {
      headers: {},
    }
  ).catch((error) => {
    if (error.response.data.error.message === "No such Item exists") {
      return Promise.resolve({});
    }
  });
};

const setSurvivorPrediction = async (
  payload: ISurvivorPredictionPayload
): Promise<ISurvivorPredictionResult> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.post(
    "tru-fan",
    `/set-survivor-prediction/${payload.tournament}/${payload.leagueName}/${user.attributes.email}`,
    {
      body: { predictions: payload.predictions },
    }
  );
};

const getPlayers = async (
  tournament: string,
  team: string
): Promise<IPlayerListResult> => {
  return API.get("tru-fan", `/get-players/${tournament}/${team}`, {
    headers: {},
  });
};

const getScore = async (
  tournament: string,
  leagueName: string
): Promise<IScoreResult> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.get(
    "tru-fan",
    `/get-score/${tournament}/${leagueName}/${user.attributes.email}`,
    {
      headers: {},
    }
  );
};

const putScore = async (
  tournament: string,
  leagueName: string,
  score: IUserScore
): Promise<IScoreResult> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.put(
    "tru-fan",
    `/update-score/${tournament}/${leagueName}/${user.attributes.email}`,
    {
      body: { score },
    }
  );
};

const setSurvivorPlayerPrediction = async (
  payload: ISurvivorPlayerPredictionPayload
): Promise<ISurvivorPlayerPredictionResult> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.post(
    "tru-fan",
    `/set-survivor-player-prediction/${payload.tournament}/${payload.leagueName}/${user.attributes.email}`,
    {
      body: { playerpredictions: payload.playerpredictions },
    }
  );
};

const getSurvivorPlayerPrediction = async (
  tournament: string,
  leagueName: string,
  user: string
): Promise<ITeamScore> => {
  return API.get(
    "tru-fan",
    `/get-survivor-player-prediction/${tournament}/${leagueName}/${user}`,
    {
      headers: {},
    }
  ).catch((error) => {
    if (error.response.data.error.message === "No such Item exists") {
      return Promise.resolve({});
    }
  });
};

const getSurvivorPlayerPredictionForMatch = async (
  tournament: string,
  leagueName: string,
): Promise<ITeamScore> => {
  const user = await Auth.currentAuthenticatedUser();
  return API.get(
    "tru-fan",
    `/get-survivor-player-prediction/${tournament}/${leagueName}/${user.attributes.email}`,
    {
      headers: {},
    }
  ).catch((error) => {
    if (error.response.data.error.message === "No such Item exists") {
      return Promise.resolve({});
    }
  });
};

// const getUserLeagueByLeageType = async (
//   tournament: string,
//   leagueType: string,
// ): Promise<IPlayerLeaguesResult> => {
//   const user = await Auth.currentAuthenticatedUser();
//   return API.get(
//     "tru-fan",
//     `/get-user-leagues-by-league-type/${tournament}/${leagueType}/${user.attributes.email}`,
//     {
//       headers: {},
//     }
//   );
// };

const getLeagueByLeageType = async (
  tournament: string,
  leagueName: string,
  leagueType: string,
): Promise<IPlayerLeaguesAsUserResult> => {
  return API.get(
    "tru-fan",
    `/get-leagues-by-league-type/${tournament}/${leagueName}/${leagueType}`,
    {
      headers: {},
    }
  );
};
const getTopPridictedPlayersCount = async (tournament: string,matchNo:number ) => {
  return API.get(
    "tru-fan",
    `/get-top-pridicted-players/${tournament}/${matchNo}`,
    {
      headers: {},
    }
  );
};
const getTop5PridictedPlayersCount = async (tournament: string ) => {
  return API.get(
    "tru-fan",
    `/get-top-5-pridicted-players/${tournament}`,
    {
      headers: {},
    }
  );
};
const getTop5PridictedPlayersCountByLeague = async (tournament: string,league:string ) => {
  return API.get(
    "tru-fan",
    `/get-top-5-pridicted-players-by-league/${tournament}/${league}`,
    {
      headers: {},
    }
  );
};

const getTop60HighestPlayersScores = async (tournament: string ) => {
  return API.get(
    "tru-fan",
    `/get-top-60-highest-players-scores/${tournament}`,
    {
      headers: {},
    }
  );
};

export default {
  create: createLeague,
  createtournament: createTournament,
  getLeague,
  getLeagueStats,
  getUserLeagues,
  getAllLeague,
  getLeagueMembers,
  setLeagueMembers, 
  setJoinLeagueMembers,
  getSurvivorPrediction,
  setSurvivorPrediction,
  getPlayers,
  getTopPridictedPlayersCount,
  getTop5PridictedPlayersCount,
  getTop5PridictedPlayersCountByLeague,
  getTop60HighestPlayersScores,
  getScore,
  putScore,
  getAllTournament,
  getAllTournamentID,
  setSurvivorPlayerPrediction,
  getSurvivorPlayerPrediction,
  // getUserLeagueByLeageType,
  getLeagueByLeageType,
  getSurvivorPlayerPredictionForMatch
};
