import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
// import { ILeague } from "../../../../api/LeagueAPI";
import DashboardAPI from "../../../../api/DashboardAPI";
import { useSnackbar } from "notistack";


interface IManageLeagueDialogProps {
  open: boolean;
  handleClose: () => void;
  tournament: any;
  setShowLoader: any;
}

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color: "#66bb6a",
    },
  },
  redioStyle: {
    paddingTop: 10,
  },
  buttonJustify: {
    justifyContent: "space-between",
  },
  checked: {},
});

export default function TournamentEditDialog(props: IManageLeagueDialogProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedValue, setSelectedValue] = useState('');
  const { open, handleClose } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {

    setSelectedValue(props.tournament.tournamentStatus);
  }, [props])

  const _saveSchedule = (e: any) => {
    e.preventDefault();


    props.setShowLoader(true);
    DashboardAPI.updateTounamnetStatus(
      props.tournament.tournament,
      selectedValue
    )
      .then(() => {
        props.setShowLoader(false);
        enqueueSnackbar("Changed Saved", {
          variant: "success",
        });
      })
      .catch(() => {
        props.setShowLoader(false);
        enqueueSnackbar("Oops! Something went wrong", {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {console.log(props.tournament)}
        <DialogTitle id="form-dialog-title">Welcome to {props.tournament.tournament} tournament </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            please update status of tournament.
          </DialogContentText>
          {/* <Grid item container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="satrtdate"
                label="Tourname start date "
                type="date"
                defaultValue="2021-11-24"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="enddate"
                label="Tournament End date"
                type="date"
                defaultValue="2021-11-24"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid> */}

          <FormControl className={classes.redioStyle}>
            <FormLabel>Tournament Status</FormLabel>
            <RadioGroup
              row
              aria-label="leagueType"
              name="leagueType"
              id="dsfdsf"
              value="dfdsfsd"
              onChange={handleChange}
            >
              <FormControlLabel
                value="Upcoming"
                id="upcoming"
                name="upcoming"
                control={<Radio color="primary" />}
                checked={selectedValue === "Upcoming"}
                label="Upcoming"
              />
              <FormControlLabel
                value="Live"
                id="live"
                name="live"
                control={<Radio color="primary" />}
                checked={selectedValue === "Live"}
                label="Live"
              />
              <FormControlLabel
                value="Completed"
                id="completed"
                name="completed"
                control={<Radio color="primary" />}
                checked={selectedValue === "Completed"}
                label="Completed"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.buttonJustify}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="default"
          >
            Cancel
          </Button>
          <Button
            onClick={_saveSchedule}
            variant="outlined"
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
