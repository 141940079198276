import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 10000,
      color:'#42a5f5',
    },
  }),
);
interface propsIns {

  show:boolean
}

export default function SimpleBackdrop(props:propsIns) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  return (
    <div  style={{zIndex:100000}} >
     
      <Backdrop className={classes.backdrop} open={props.show} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
