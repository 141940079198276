import React, { useEffect, useState } from "react";
// import JumboButton from "./modules/components/JumboButton";
// import { Grid } from "@material-ui/core";
import { URL } from "../../Routes";
import { LEAGUE_ACTIONS } from "../../reducers/LeagueReducer";
import { reducers } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import {  sortBy, reverse, get } from "lodash-es";
import { ILeague } from "../../api/LeagueAPI";
import TournamnetView from "./TouranamentListSwapableView";

interface IInsightProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  admin: boolean;
  history: any;
}



export default function Insights(props: IInsightProps) {
  if (!props.isAuthenticated) props.history.push(URL.HOME);
  if (props.admin)
    props.history.push(
      URL.LEAGUES.TOURNAMENT_EDIT
    );
  const dispatch = useDispatch();
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [allTournament, setAllTournament] = useState([] as ILeague[]);


  useEffect(() => {
    const getAllTournament = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_TOURNAMENT});
    };
    getAllTournament();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  // store watcher
  useEffect(() => {
    const updateAllTournament = async () => {
      const tournaments = await store.all_tournament;
      const sortedAllTournamnet= reverse(sortBy(get(tournaments, "result.Items"), (l) => l.created || ""));    
       setAllTournament(sortedAllTournamnet);
    };
    updateAllTournament();
  }, [store.all_tournament]);

  const navigateToLeaguePlayer = (tournament: string,type: StringConstructor) => {
    const url = URL.LEAGUES.TOURNAMNET_SUMMARY.replace(":game", tournament).replace(":type",type);
    props.history.push(url);
  };
  return (
     <TournamnetView Data={allTournament} navigateToLeague={navigateToLeaguePlayer} />
  );
}