import React from "react";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  Typography,
  Divider,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { map } from "lodash-es";

interface ITournamentHistory {
  match: {
    left: string;
    right: string;
    TotalPlayerScore: number;
  };
  playerScores: Array<{
    player: string;
    playerName: string;
    score: number;
  }>;
  matchIndex: number;
}

function MatchList(props: ITournamentHistory) {
  const {
    match,
    playerScores,
    matchIndex,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow style={{ border: "1px solid #E0E0E0" }} key={matchIndex}>
        <TableCell>Match #{matchIndex + 1}</TableCell>
        <TableCell>
          {match.left} VS {match.right}
        </TableCell>
        <TableCell align="right">{match.TotalPlayerScore?.toFixed(0)}</TableCell>
        <TableCell style={{ padding: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Match History
              </Typography>
              <Divider />
              <Table
                size="small"
                aria-label="purchases"
                style={{ border: "1px solid #E0E0E0" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Player Name</TableCell>
                    <TableCell align="right">Points</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(playerScores, (el) => (
                    <>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {el.player} - {el.playerName}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {el.score}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default MatchList;
