import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Countdown, { CountdownRenderProps } from "react-countdown-now";
import { IPlayerPrediction } from "../../../../api/LeagueAPI";
import theme from "../../../../theme";
import PlayerAutocompleteBoth from "./PlayerAutocompleteBoth";
import PlayerAutocompleteLeft from "./PlayerAutocompleteLeft";
import PlayerAutocompleteRight from "./PlayerAutocompleteRight";
import { CardHeader } from "@material-ui/core";
import {
  IUserMatch,
  IConfidenceScore,
  getValidFreeHits,
  IPowerPlayPoints,
} from "../views/SurvivorConfidencePlayer";
import { isEmpty, includes } from "lodash-es";
import {
  subHours,
  differenceInMilliseconds,
  isBefore,
  differenceInMinutes,
  isAfter,
  isWithinInterval,
} from "date-fns";

const MAX_32_BIT_INTEGER = 0x7fffffff;

const useStyles = makeStyles({
  card: {
    display: "flex",
    cursor: "pointer",
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  cardSelected: {
    backgroundColor: theme.palette.secondary.dark,
    color: "white",
  },
  rootPaper: {
    padding: theme.spacing(2),
  },
  correctPrediction: {
    background: "#91ff91a6",
  },
  wrongPrediction: {
    background: "#ff9d9d63",
  },
});

interface ITeamSwitcherProps {
  userMatch: IUserMatch;
  tournament: string;
  index: number;
  minimumScoreAssignable: number;
  confidenceScores: IConfidenceScore[];
  powerPlayPoints: IPowerPlayPoints;
  updatePredictionHandler: (index: number, prediction: IPlayerPrediction) => void;
  isEditMode: boolean;
  save?: () => void;
  edit?: (index: number, matchStatus: MatchStatus) => void;
}

export interface IPlayerLeaguesOption {
  player: string;
}

export interface IPlayPlayerOption {
  player: string;
}

export enum MatchStatus {
  NOT_STARTED,
  QUARTER,
  HALF,
  THREE_QUARTER,
  END_PHASE,
  COMPLETED,
}

export const statusCostMap = {
  [MatchStatus.NOT_STARTED]: 100,
  [MatchStatus.QUARTER]: 100,
  [MatchStatus.HALF]: 500,
  [MatchStatus.THREE_QUARTER]: 1000,
  [MatchStatus.END_PHASE]: Number.POSITIVE_INFINITY,
  [MatchStatus.COMPLETED]: Number.POSITIVE_INFINITY,
};

const getMatchStatus = (
  start: string,
  end: string,
  completed: boolean
): [number, MatchStatus] => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const now = new Date();
  if (completed || isAfter(now, endDate)) return [100, MatchStatus.COMPLETED];
  if (isBefore(now, startDate)) return [0, MatchStatus.NOT_STARTED];
  const matchDuration = differenceInMinutes(endDate, startDate);
  const elapsedTime = differenceInMinutes(now, startDate);
  const percentCompleted = Math.round((elapsedTime / matchDuration) * 100);
  if (percentCompleted < 25) return [percentCompleted, MatchStatus.QUARTER];
  if (percentCompleted < 50) return [percentCompleted, MatchStatus.HALF];
  if (percentCompleted < 80)
    return [percentCompleted, MatchStatus.THREE_QUARTER];
  if (percentCompleted < 100) return [percentCompleted, MatchStatus.END_PHASE];
  return [percentCompleted, MatchStatus.COMPLETED];
};

function TeamSwitcherPlayer(props: ITeamSwitcherProps) {
  const classes = useStyles();
  const {
    updatePredictionHandler,
    minimumScoreAssignable,
    tournament,
    userMatch: {
      match: {
        left,
        right,
        start,
        completed: matchCompleted,
        bat1: matchBat1,
        bat2: matchBat2,
        bat3: matchBat3,
        bowl1: matchBowl1,
        bowl2: matchBowl2,
        bowl3: matchBowl3,
        allrounder: matchAllrounder,
        winner,
        end,
      },
      prediction,
    },
    index,
    save,
    edit,
    isEditMode,
    powerPlayPoints: { freeHits, usedFreeHits, remaining },
  } = props;
  const [team, setTeam] = useState(prediction.team);
  const [mom, setMom] = useState(prediction.mom);
  const [bat1, setBat1] = useState(prediction.bat1);
  const [bat2, setBat2] = useState(prediction.bat2);
  const [bat3, setBat3] = useState(prediction.bat3);
  const [bowl1, setBowl1] = useState(prediction.bowl1);
  const [bowl2, setBowl2] = useState(prediction.bowl2);
  const [bowl3, setBowl3] = useState(prediction.bowl3);
  const [allrounder, setAllrounder] = useState(prediction.allrounder);
  const [confidence, setConfidence] = useState(prediction.confidence);
  const [, setCache] = useState(prediction.confidence);
  const [currTime, setCurrTime] = useState(new Date());

  const [, matchStatus] = getMatchStatus(
    start,
    end,
    matchCompleted
  );

  const matchInterval = {
    start: subHours(new Date(start), 1),
    end: new Date(end),
  };
  const timeout = differenceInMilliseconds(
    subHours(new Date(start), 0),
    new Date()
  );
  const [locked, setLocked] = useState(timeout < 0 && !isEditMode);

  const disabled = matchCompleted || locked || right === "TBD";

  const useFreeHit =
    !isEmpty(getValidFreeHits(freeHits, usedFreeHits)) &&
    !includes(
      [MatchStatus.THREE_QUARTER, MatchStatus.END_PHASE, MatchStatus.COMPLETED],
      matchStatus
    );
  const canEdit = remaining - statusCostMap[matchStatus] >= 0 || useFreeHit;

  useEffect(() => {
    // save prediction when time is up
    const startTimer = () => {
      if (!!save && timeout < MAX_32_BIT_INTEGER && timeout > 0) {
        return setTimeout(() => {
          if (!team) {
            setConfidence(minimumScoreAssignable);
            setCache(minimumScoreAssignable);
          }
          updatePredictionHandler(index, {
            team,
            mom,
            bat1,
            bat2,
            bat3,
            bowl1,
            bowl2,
            bowl3,
            allrounder,
            confidence: confidence || minimumScoreAssignable,
          });
          setLocked(true);
          setTimeout(save);
        }, timeout);
      }
    };
    // start updating matching progress every minute after match start.
    const startTicker = () => {
      if (isWithinInterval(currTime, matchInterval)) {
        return setInterval(() => setCurrTime(new Date()), 60000);
      }
    };
    const timerId = startTimer();
    const intervalId = startTicker();
    return function cleanUp() {
      if (timerId) clearTimeout(timerId);
      if (intervalId) clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save]);

  useEffect(() => {
    updatePredictionHandler(index, { team, mom, bat1, bat2, bat3, bowl1, bowl2, bowl3, allrounder, confidence });
    // only update prediction if team, mom, or confidence is to be updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bat1, bat2, bat3, bowl1, bowl2, bowl3, allrounder]);

  useEffect(() => {
    setTeam(prediction.team);
    setMom(prediction.mom);
    setBat1(prediction.bat1);
    setBat2(prediction.bat2);
    setBat3(prediction.bat3);
    setBowl1(prediction.bowl1);
    setBowl2(prediction.bowl2);
    setBowl3(prediction.bowl3);
    setAllrounder(prediction.allrounder);
    setConfidence(prediction.confidence);
    setCache(prediction.confidence);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prediction]);

  const _bat1Handler = (e: object, value: IPlayerLeaguesOption | null) =>
    !matchCompleted && setBat1(value?.player || "");
  const _bat2Handler = (e: object, value: IPlayerLeaguesOption | null) =>
    !matchCompleted && setBat2(value?.player || "");
  const _bat3Handler = (e: object, value: IPlayerLeaguesOption | null) =>
    !matchCompleted && setBat3(value?.player || "");
  const _bowl1Handler = (e: object, value: IPlayerLeaguesOption | null) =>
    !matchCompleted && setBowl1(value?.player || "");
  const _bowl2Handler = (e: object, value: IPlayerLeaguesOption | null) =>
    !matchCompleted && setBowl2(value?.player || "");
  const _bowl3Handler = (e: object, value: IPlayerLeaguesOption | null) =>
    !matchCompleted && setBowl3(value?.player || "");
  const _allrounderHandler = (e: object, value: IPlayerLeaguesOption | null) =>
    !matchCompleted && setAllrounder(value?.player || "");

  const startDate = new Date(start);
  const endDate = new Date(end);
  const _timeRemainingRenderer = ({
    days,
    hours,
    minutes,
    completed,
  }: CountdownRenderProps) => {
    if (matchCompleted && completed) {
      return (
        <div>
          <div>{`Winner: ${winner}`}</div>
        </div>
      );
    } else if (timeout < 0) {
      switch (matchStatus) {
        case MatchStatus.NOT_STARTED:
          return (
            <div onClick={() => !!edit && canEdit && edit(index, matchStatus)}>
              <div>{`Match start in ${minutes} minute(s)`}</div>
              <div>{`Prediction locked`}</div>
            </div>
          );
        case MatchStatus.QUARTER:
          return (
            <div onClick={() => !!edit && canEdit && edit(index, matchStatus)}>
              <div>{`Match started @ ${startDate.toLocaleTimeString()}`}</div>
              <div>{`Prediction locked`}</div>
            </div>
          );
        case MatchStatus.HALF:
          return (
            <div onClick={() => !!edit && canEdit && edit(index, matchStatus)}>
              <div>{`Match started @ ${startDate.toLocaleTimeString()}`}</div>
              <div>{`Prediction locked`}</div>
            </div>
          );
        case MatchStatus.THREE_QUARTER:
          return (
            <div onClick={() => !!edit && canEdit && edit(index, matchStatus)}>
              <div>{`Match started @ ${startDate.toLocaleTimeString()}`}</div>
              <div>{`Prediction locked`}</div>
            </div>
          );
        case MatchStatus.END_PHASE:
          return (
            <div>
              <div>{`Match started @ ${startDate.toLocaleTimeString()}`}</div>
              <div>{`Prediction locked`}</div>
            </div>
          );
        case MatchStatus.COMPLETED:
          return (
            <div>
              <div>{`Match completed @ ${endDate.toLocaleTimeString()} | Results Pending`}</div>
              <div>{`Prediction locked`}</div>
            </div>
          );
      }
    }
    return `${days} day(s), ${hours} hour(s), ${minutes} minute(s)`;
  };

  return (
    <Card
      className={clsx(
        classes.rootPaper,
        matchCompleted &&
          (team === winner
            ? classes.correctPrediction
            : classes.wrongPrediction)
      )}
      variant="outlined"
    >
      <CardHeader
        title={
          <>
          <Box fontWeight="fontWeightBold" fontSize="h4.fontSize">
            {`Match #${index + 1}`}
          </Box>
          <Box fontSize="h5.fontSize">
          {`${left} vs ${right}`}
          </Box>
          </>
        }
        subheader={
          <>
            <div>{`${startDate.toDateString()}`}</div>
            <Countdown date={startDate} renderer={_timeRemainingRenderer} />
            <Typography component="h2" variant="h5">
              {!!confidence ? `Confidence ${confidence}%` : ""}
            </Typography>
          </>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} md={4}>
            <PlayerAutocompleteLeft
                value={{ player: bat1 }}
                tournament={tournament}
                leftTeam={left}
                wrong={matchCompleted && bat1 !== matchBat1}
                correct={matchCompleted && bat1 === matchBat1}
                label="Select Bat 1"
                selectedPlayerLIst={[bat1,bat2,bat3,bowl1,bowl2,bowl3,allrounder]}
                changeHandler={_bat1Handler}
                disabled={disabled}
                placeholder={`Select ${left} Bastman 1`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PlayerAutocompleteRight
                value={{ player: bat2 }}
                tournament={tournament}
                rightTeam={right}
                selectedPlayerLIst={[bat1,bat2,bat3,bowl1,bowl2,bowl3,allrounder]}
                wrong={matchCompleted && bat2 !== matchBat2}
                correct={matchCompleted && bat2 === matchBat2}
                changeHandler={_bat2Handler}
                disabled={disabled}
                label="Select Bat 2"
                placeholder={`Select ${right} Bastman 2`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PlayerAutocompleteBoth
                value={{ player: bat3 }}
                tournament={tournament}
                leftTeam={left}
                selectedPlayerLIst={[bat1,bat2,bat3,bowl1,bowl2,bowl3,allrounder]}
                rightTeam={right}
                disabled={disabled}
                wrong={matchCompleted && bat3 !== matchBat3}
                correct={matchCompleted && bat3 === matchBat3}
                changeHandler={_bat3Handler}
                label="Select Bat 3"
                placeholder={`Select ${left} or ${right} Bastman 1`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PlayerAutocompleteLeft
                value={{ player: bowl1 }}
                tournament={tournament}
                leftTeam={left}
                selectedPlayerLIst={[bat1,bat2,bat3,bowl1,bowl2,bowl3,allrounder]}
                wrong={matchCompleted && bowl1 !== matchBowl1}
                correct={matchCompleted && bowl1 === matchBowl1}
                changeHandler={_bowl1Handler}
                disabled={disabled}
                label="Select Bowl 1"
                placeholder={`Select ${left} Bowler 1`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PlayerAutocompleteRight
                value={{ player: bowl2 }}
                tournament={tournament}
                rightTeam={right}
                selectedPlayerLIst={[bat1,bat2,bat3,bowl1,bowl2,bowl3,allrounder]}
                wrong={matchCompleted && bowl2 !== matchBowl2}
                correct={matchCompleted && bowl2 === matchBowl2}
                changeHandler={_bowl2Handler}
                disabled={disabled}
                label="Select Bowl 2"
                placeholder={`Select ${right} Bowler 2`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PlayerAutocompleteBoth
                value={{ player: bowl3 }}
                tournament={tournament}
                leftTeam={left}
                selectedPlayerLIst={[bat1,bat2,bat3,bowl1,bowl2,bowl3,allrounder]}
                rightTeam={right}
                wrong={matchCompleted && bowl3 !== matchBowl3}
                correct={matchCompleted && bowl3 === matchBowl3}
                changeHandler={_bowl3Handler}
                disabled={disabled}
                label="Select Bowl 3"
                placeholder={`Select ${left} or ${right} Bowler 3`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PlayerAutocompleteBoth
                value={{ player: allrounder }}
                tournament={tournament}
                leftTeam={left}
                rightTeam={right}
                selectedPlayerLIst={[bat1,bat2,bat3,bowl1,bowl2,bowl3,allrounder]}
                wrong={matchCompleted && allrounder !== matchAllrounder}
                correct={matchCompleted && allrounder === matchAllrounder}
                changeHandler={_allrounderHandler}
                disabled={disabled}
                label="Select Allrounder"
                placeholder={`Select ${left} or ${right} Allrounder 1`}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default React.memo(TeamSwitcherPlayer);
