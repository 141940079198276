import React from "react";
import ProductHero from "./modules/views/ProductHero";
import ProductHowItWorks from "./modules/views/ProductHowItWorks";
import AppFooter from "./modules/views/AppFooter";
import { URL } from "../../Routes";

interface IIndexProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  history: any;
}

function Index(props: IIndexProps) {
  if (props.isAuthenticated) props.history.push(URL.LEAGUES.HOME);

  return (
    <>
      <ProductHero />
      <ProductHowItWorks />
      <AppFooter />
     </>
  );
}

export default Index;
