import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  Box,
  AppBar,
  // Tabs,
  // Tab,
  // Link,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TablePagination,
  TableRow,
  TableCell
} from "@material-ui/core";
// import { Link as RouterLink } from "react-router-dom";
import { map, slice } from "lodash-es";
import MuiTableHead from "@material-ui/core/TableHead";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "90.00%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionStyle: {
    padding: "1px 0px",
  },
  leagueTitle: {
    paddingTop: theme.spacing(2),
  },
  tabTitle: {
    backgroundColor: theme.palette.primary.light,
  },
  tabSelected: {
    color: theme.palette.primary.dark + " !important",
  },
  RowPaper: {
    margin: "5px",
    padding: "3px",
    borderRadius: "2px",
    paddingLeft: "10px",
    // display: 'flex',
    // justifyContent:'space-between'
  },
  RowPaperDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

interface ITabPanelProps {
  children: JSX.Element | string;
  value: number;
  index: number;
  classes: string;
  [key: string]: string | number | JSX.Element;
}

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: '#80d6ff',
  }
}))(MuiTableHead);

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, classes, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={classes}>{children}</Box>}
    </Typography>
  );
}

// function a11yProps(index: number) {
//   return {
//     id: `full-width-tab-${index}`,
//     "aria-controls": `full-width-tabpanel-${index}`,
//   };
// }

const ROWS_PER_PAGE = 5;

function ScoreBoard({ data, tournament }: any) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  // const tabCustomStyles = {
  //   selected: classes.tabSelected,
  // };

  const [page, setPage] = useState(0);

  // const handleChange = (event: object, newValue: number) => {
  //   setValue(newValue);
  // };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Grid item md={6}>
      <AppBar
        position="static"
        color="primary"
        classes={{
          colorPrimary: classes.tabTitle,

        }}
      >
        <Typography className={classes.leagueTitle} variant="h5" noWrap>
          {tournament}
        </Typography>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          classes={classes.expansionStyle}
        >
          <>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
              <TableHead>
          <TableRow>
            <TableCell component="th" scope="row">#Rank</TableCell>
            <TableCell align="left">League Name</TableCell>
            <TableCell align="right">User Id</TableCell>
            <TableCell align="right">Score</TableCell>
          </TableRow>
        </TableHead>
                <TableBody>
                  {map(
                    slice(
                      data,
                      page * ROWS_PER_PAGE,
                      page * ROWS_PER_PAGE + ROWS_PER_PAGE
                    ),
                    (data2: any, rank, i) => (
                      <>

                         <TableRow>
                         <TableCell component="th" scope="row">{`#${data2.index}`}</TableCell>                        
                         <TableCell align="left">{data2.tournamentLeague.split("/")[1]}</TableCell>                        
                         <TableCell align="right">{data2.username}</TableCell>
                         <TableCell align="right">{parseInt(data2.totalScore)}</TableCell>
                        </TableRow>
                      </>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              count={data.length}
              rowsPerPage={ROWS_PER_PAGE}
              page={page}
              onChangePage={handleChangePage}
            />
          </>
        </TabPanel>
      </SwipeableViews>
    </Grid>
  );
}

export default React.memo(ScoreBoard);
