import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IPlayerLeaguesOption } from "./TeamSwitcherPlayer";
import LeagueAPI, { IPlayerListResult } from "../../../../api/LeagueAPI";
import { get } from "lodash-es";
import TextField from "@material-ui/core/TextField";


interface IPlayerAutocompleteProps {
  value: IPlayerLeaguesOption;
  tournament: string;
  leftTeam: string;
  label: string;
  placeholder: string;
  disabled: boolean;
  wrong?: boolean;
  correct?: boolean;
  selectedPlayerLIst: any
  changeHandler: (e: object, value: IPlayerLeaguesOption | null) => void;
}

// const useStyles = makeStyles(theme => ({
// }));

export default function PlayerAutocompleteLeft(
  props: IPlayerAutocompleteProps
) {
  const {
    leftTeam,
    value,
    tournament,
    label,
    changeHandler,
    placeholder,
    disabled,
    selectedPlayerLIst,
    wrong,
  } = props;
  // console.log(selectedPlayerLIst);

  const [players, setPlayers] = useState<IPlayerLeaguesOption[]>([]);
  const [openPlayers, setOpenPlayers] = React.useState(false);
  const loading = openPlayers && players.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const leftPlayerResponse = await LeagueAPI.getPlayers(
        tournament,
        leftTeam
      );
      const players = [..._playerResponseFormatter(leftPlayerResponse)];

      if (active) {
        setPlayers(players);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const _playerResponseFormatter = (playerResponse: IPlayerListResult) => {
    const { team, players, isChecked } = get(playerResponse, "result.Item", {});
    let arr: Array<any> = [];
    players.forEach((player: any) => {
      if (!selectedPlayerLIst.includes(player)) {
        console.log(player);
        arr.push({ team, player, isChecked })
      }
    });
    console.log(arr)
    return arr;
  };

  useEffect(() => {
    if (!openPlayers) {
      setPlayers([]);
    }
  }, [openPlayers]);

  return (
    <React.Fragment>
      <Autocomplete
        options={players as IPlayerLeaguesOption[]}
        getOptionLabel={(option) => option.player}
        renderOption={(option) => <span>{option.player}</span>}
        open={openPlayers}
        onOpen={() => setOpenPlayers(true)}
        onClose={() => setOpenPlayers(false)}
        onChange={changeHandler}
        disabled={disabled}
        loading={loading}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={wrong}
            variant="outlined"
            placeholder={placeholder}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </React.Fragment>
  );
}
