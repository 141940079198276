import React, { useState, useEffect } from "react";
// import SwipeableViews from "react-swipeable-views";
// import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  Box,
  // AppBar,
  // Tabs,
  // Tab,
  // TableContainer,
  // Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  // TablePagination,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Divider from "@material-ui/core/Divider";
import DashboardAPI, {
  IGameSchedule,
  // ITournamentScore,
  TotalScore,
  IUserScore,
} from "../../../../api/DashboardAPI";
import MatchList from "./MatchList";
import { isEmpty, map, get } from "lodash-es";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: "90.00%",
//     flexShrink: 0,
//   },
//   secondaryHeading: {
//     fontSize: theme.typography.pxToRem(15),
//     color: theme.palette.text.secondary,
//   },
//   expansionStyle: {
//     padding: "1px 0px",
//   },
//   leagueTitle: {
//     paddingTop: theme.spacing(2),
//   },
//   tabTitle: {
//     backgroundColor: theme.palette.primary.light,
//   },
//   tabSelected: {
//     color: theme.palette.primary.dark + " !important",
//   },
// }));

// interface ITabPanelProps {
//   children: JSX.Element | string;
//   value: number;
//   index: number;
//   classes: string;
//   [key: string]: string | number | JSX.Element;
// }

// function TabPanel(props: ITabPanelProps) {
//   const { children, value, index, classes, ...other } = props;

//   return (
//     <Typography
//       component="div"
//       role="tabpanel"
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box className={classes}>{children}</Box>}
//     </Typography>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `full-width-tab-${index}`,
//     "aria-controls": `full-width-tabpanel-${index}`,
//   };
// }

export function TournamentLeaderboard(props: {
  row: { score: IUserScore; rank: number };
  leagueName: string;
  totalScore: number;
  tournament: string;
  userId: string;
  matchSchedule: IGameSchedule[];
}) {
  const {
    row: { score },
    userId,
    leagueName,
    totalScore,
    tournament,
  } = props;
  const [teamOpen, setTeamOpen] = useState(false);

  const [players, setPlayers] = useState({} as TotalScore);
  const [openPlayers, setOpenPlayers] = React.useState(false);
  const loading = openPlayers && players.leagueName !== "";

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    (async () => {
      const leftPlayerResponse = await DashboardAPI.userAllTournamnetPoints(
        tournament,
        leagueName,
        userId
      );
      const sortedMatchSchedule = get(leftPlayerResponse, "result.Item", {});
      if (active) {
        setPlayers(sortedMatchSchedule);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!openPlayers) {
      setPlayers({} as TotalScore);
    }
  }, [openPlayers]);

  console.log(players);

  const handlePlayerChange = () => {
    setTeamOpen(!teamOpen);
    setOpenPlayers(true);
  };


  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          {score.username}
        </TableCell>
        <TableCell align="right">{players.leagueName === leagueName && players.userId === score.userId ? `${players.totalScore?.toFixed(0)}` : totalScore?.toFixed(0)}</TableCell>
        <TableCell style={{ padding: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handlePlayerChange}
          >
            {teamOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={teamOpen} timeout="auto" unmountOnExit>
            <Box>
              <Typography variant="h6" gutterBottom component="div">
                Tournament history
              </Typography>
              <Divider />
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {!isEmpty(players) ? (
                    players.leagueName === leagueName &&
                      players.userId === score.userId ? (
                      <>
                        {map(players.matches, (team, index) => (
                          <>
                            <MatchList
                              match={team.match}
                              playerScores={team.playerScores}
                              matchIndex={index}
                            />
                          </>
                        ))}
                      </>
                    ) : null
                  ) : (
                    <Grid item xs={12} style={{ paddingTop: 50 }}>
                      Lodding...
                    </Grid>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function CurrentMatchScore(props: {
  row: { score: IUserScore; rank: number };
  leagueName: string;
  totalScore: number;
  tournament: string;
  userId: string;
  matchSchedule: IGameSchedule[];
}) {
  const {
    row: { score },
    userId,
    leagueName,
    totalScore,
    tournament,
  } = props;
  const [teamOpen, setTeamOpen] = useState(false);
  const [lod, setlod] = useState("Today Matches")

  const handlePlayerChange = () => {
    setTeamOpen(!teamOpen);
    setOpenPlayersTodayMatch(true);
    // updateUserIDLeagues();
  };

  const [playersTodayMatch, setPlayersTodayMatch] = useState({} as TotalScore);
  const [openPlayersTodayMatch, setOpenPlayersTodayMatch] = React.useState(false);
  const loadingTodayMatch =
    openPlayersTodayMatch && playersTodayMatch.leagueName !== "";

  useEffect(() => {
    let active = true;

    if (!loadingTodayMatch) {
      return undefined;
    }

    (async () => {
      const leftPlayerResponseTodayMatch = await DashboardAPI.userAllTournamnetPointsTodayMatch(
        tournament,
        leagueName,
        userId
      );
      const totalScore = get(leftPlayerResponseTodayMatch, "result.Item", {});

      console.log("ok");
    
      if (active) {
        if(totalScore.matches.length === 0){
          setlod("No Matches Today")
        };
        setPlayersTodayMatch(totalScore);
        console.log(totalScore);
        
      
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTodayMatch]);

  useEffect(() => {
    if (!openPlayersTodayMatch) {
      setPlayersTodayMatch({} as TotalScore);
    }
  }, [openPlayersTodayMatch]);
  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" scope="row">
          {score.username}
        </TableCell>
        <TableCell align="right">{totalScore?.toFixed(0)}</TableCell>
        <TableCell style={{ padding: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handlePlayerChange}
          >
            {teamOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={teamOpen} timeout="auto" unmountOnExit>
            <Box>
              <Typography variant="h6" gutterBottom component="div">
                
                {lod}
              </Typography>
              <Divider />
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {!isEmpty(playersTodayMatch) ? (
                    playersTodayMatch.leagueName === leagueName &&
                      playersTodayMatch.userId === score.userId ? (
                      <>
                        {map(playersTodayMatch.matches, (team, index) => (
                          <>
                            <MatchList
                              match={team.match}
                              playerScores={team.playerScores}
                              matchIndex={index}
                            />
                          </>
                        ))}
                      </>
                    ) : null
                  ) : (
                    <Grid item xs={12} style={{ paddingTop: 50 }}>
                      
                      Lodding...

                    </Grid>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

