import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import theme from "../../../../theme";
import PlayerList from "./PlayerList";
import { IMatch, IPlayerGameScoreBoard } from "../../../../api/DashboardAPI";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from "@material-ui/icons/Edit";
import {
  map,
  get,
} from "lodash-es";
import LeagueAPI, { IPlayerListResult } from "../../../../api/LeagueAPI";


const useStyles = makeStyles({
  card: {
    display: "flex",
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  cardSelected: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
  },
  dividerclass: {
    marginBottom: theme.spacing(1),
  },
  rootPaper: {
    padding: theme.spacing(2),
  },
  winnerSelection: {
    justifyContent: "space-evenly",
  },
  root: {
    "& > *": {
      borderBottom: "unset",
      margin: theme.spacing(1),
    },
  },

  rootbtn: {
    height: 55,
    color: "#ffffff",
  },
  inputsetup: {
    margin: theme.spacing(1),
  },
});

interface IMatchEditorProps {
  match: IMatch;
  tournament: string;
  index: number;
  updateMatchHandler: (index: number, match: IPlayerGameScoreBoard) => void;
  setShowLoader: any
}

export interface IPlayerOption {
  team: string;
  player: string;
}

export interface IPlayPlayerOption {
  team: string;
  player: string;
}

export default function MatchEditorPlayer(props: IMatchEditorProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openManage, setOpenManage] = useState(false);

  const {
    tournament,
    match: {
      left,
      right,
      start,
      completed: matchCompleted,
      mom: matchMom,
    },
    index,
    // playerCountMap
  } = props;

  const [leftTeamName] = useState(left);
  const [rightTeamName] = useState(right);
  const [matchStart] = useState(start);
  const [mom] = useState(matchMom);
  const startDate = new Date(matchStart);
  const [leftPlayers, setLeftPlayers] = useState<IPlayerOption[]>([]);
  const [leftOpenPlayers, setLeftOpenPlayers] = useState(false);
  const leftloading = leftOpenPlayers && leftPlayers.length === 0;

  useEffect(() => {
    let active = true;
    if (!leftloading) {
      return undefined;
    }
    (async () => {
      const leftPlayerResponse = await LeagueAPI.getPlayers(
        props.tournament,
        leftTeamName
      );
      const players = [..._playerResponseFormatter(leftPlayerResponse)];
      if (active) {
        setLeftPlayers(players);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftloading]);
  const _playerResponseFormatter = (playerResponse: IPlayerListResult) => {
    const { team, players } = get(playerResponse, "result.Item", {});
    return map(players, (player) => ({ team, player }));
  };

  const handleChange = () => {
    setOpenManage(!open)
    setLeftOpenPlayers(true);
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            <TableRow className={classes.root}>
              <TableCell component="th" scope="row" style={{ width: '10%' }}>
                {`Match #${index + 1}`}
              </TableCell>
              <TableCell align="left" style={{ width: '15%' }}> {`${left} VS ${right}`}</TableCell>
              <TableCell align="left" style={{ width: '20%' }}>{`${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()}`}</TableCell>
              <TableCell align="left" style={{ width: '55%' }}>
              </TableCell>
              <TableCell align="right" style={{ width: '5%' }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell align="right" style={{ width: '5%' }}>
                <IconButton
                  aria-label="settings"
                  onClick={handleChange}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <Typography variant="h6" gutterBottom component="div">
                      This match History
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell align="right">Point</TableCell>
                          <TableCell align="right">Winner</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Abc
                          </TableCell>
                          <TableCell>Abc</TableCell>
                          <TableCell align="right">Abc</TableCell>
                          <TableCell align="right">Abc</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <PlayerList
        open={openManage}
        setShowLoader={props.setShowLoader}
        // playerCountMap={playerCountMap}
      handleClose={() => setOpenManage(false)}
      teamOpen={() => setLeftOpenPlayers(true)}
      leftTeamList={leftPlayers}
      value={{
        team: `${rightTeamName} + ${leftTeamName}`,
        player: mom
      }}
      tournament={tournament}
      index={index}
      leftTeam={left}
      rightTeam={right}
      wrong={matchCompleted && mom !== matchMom}
      correct={matchCompleted && mom === matchMom}
      isOptionDisabled={(selectedOption: { isdisabled: any }) =>
        selectedOption.isdisabled
      }
      />
    </>
  );
}