import React, { useEffect, useState } from "react";
// import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSnackbar } from "notistack";
import DashboardAPI, {
  // ITournamentPlayer
} from "../../api/DashboardAPI";
import LeagueAPI from "../../api/LeagueAPI";
import * as CSV from 'csv-string';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.common.white,
  },
  upload: {
    color: "primary"
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function CreateTournament(props:any) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [tournamentList, setTournamnetList] = useState([{ tournament: "Loading..." }])
  const [state, setState] = useState({
    tournament: "",

  })

  useEffect(() => {
    LeagueAPI.getAllTournament().then((list: any) => {


      console.log(list.result.Items)
      setTournamnetList(list.result.Items)
    }).catch((e) => {

      alert("Oops! Can't get Touranament List");
    });


  }, [])

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setState({
      ...state,
      [name]: value
    });
  };

  const [tournamentSchedule, settournamentSchedule] = useState([]);

  function readFileDataAsBase64(e: any) {
    const file = e.target.files[0];

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result);
      };

      reader.onerror = (err: any) => {
        reject(err);
      };

      reader.readAsBinaryString(file);
    });
  }
  const handle2Change = async (e: any) => {
    const temp: any = await readFileDataAsBase64(e)
    console.log(temp)
    const schdeuleCsv: any = CSV.parse(temp);
    console.log(schdeuleCsv);
    settournamentSchedule(schdeuleCsv)

  }






  const _saveSchedule = (e: any) => {
    e.preventDefault();


    props.setShowLoader(true);
    DashboardAPI.playerUploader(
      state.tournament,
      tournamentSchedule
    )
      .then(() => {
        props.setShowLoader(false);
        enqueueSnackbar("Changed Saved", {
          variant: "success",
        });
      })
      .catch(() => {
        props.setShowLoader(false);
        enqueueSnackbar("Oops! Something went wrong", {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Upload Player List
          </Typography>
        </div>

        <form onSubmit={_saveSchedule} >
          {" "}
          <div className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Tournament</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state.tournament}
                    name="tournament"
                    fullWidth
                    required
                    onChange={handleChange}
                    label="Tournament"
                  >
                    <MenuItem value="">
                      <em>Select Tournament</em>
                    </MenuItem>
                    {tournamentList.map((ele, i) => {
                      return (

                        <MenuItem key={i} value={ele.tournament}>{ele.tournament}</MenuItem>
                      )

                    })}

                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="tournament"
                  label="Tournament"
                  name="tournament"
                  value={state.tournament}
                  onChange={handleChange}
                />
              </Grid> */}
              {/* <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Sports</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.sport}
                  name="sport"
                  fullWidth
                  onChange={handleChange}
                  label="Sports"
                >
                  <MenuItem value="">
                    <em>Select Sport</em>
                  </MenuItem>
                  <MenuItem value={"Cricket"}>Cricket</MenuItem>
                  <MenuItem value={"Football"}>Football</MenuItem>
                </Select>
              </FormControl>          
                        </Grid>     */}


              <Grid item xs={12}>
                <input
                  accept=".csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  name="player"
                  required
                  onChange={(e) => handle2Change(e)}
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    fullWidth
                    className={classes.upload}
                  >
                    Attached Team & Players CSV file
                  </Button>
                </label>
              </Grid>
            </Grid>
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}

          >
            Upload Players List
          </Button>
        </form>
      </Container>
    </div>
  )
}
