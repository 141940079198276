import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { URL } from "../../../../Routes";
import { isEmpty, map, sortBy, reverse, get } from "lodash-es";
import { LEAGUE_ACTIONS } from "../../../../reducers/LeagueReducer";
import { useDispatch, useSelector } from "react-redux";
import { reducers } from "../../../../reducers";
import ManageLeagueDialog from "../../modules/components/ManageLeagueDialog";
import { ILeague } from "../../../../api/LeagueAPI";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

interface TabPanelProps {
  children: JSX.Element | string;
  index: number;
  value: number;
  [key: string]: string | number | JSX.Element;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const tabHeight = "24px";
const useStyles = makeStyles((theme) => ({
  cardroot: {
    minWidth: 275,
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  breadcrumbs: {
    "& > * + *": {
      marginTop: theme.spacing(2),
      marginBottom: 10,
    },
  },
  leagueTitle: {
    padding: theme.spacing(2),
    color: "#ffffff",
    backgroundColor: "#42a5f5",
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  leaguesSection: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  cardsize: {
    height: 130,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  buttonJustify: {
    justifyContent: "space-between",
  },
  playPlayer: {
    fontColor: "#0063cc",
    borderColor: "#0063cc",
  },
  cardHeader: {
    backgroundColor: "#42a5f5",
    color: "#FFFFFF",
  },
  tabTitle: {
    backgroundColor: "#ffffff",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "90.00%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionStyle: {
    padding: "1px 0px",
  },
  tabSelected: {
    color: theme.palette.primary.dark + " !important",
  },
}));

interface IDashboardProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  history: any;
  tournament: ILeague;
}

export default function ManageLeagueTabs(props: IDashboardProps) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const store: any = useSelector((state: reducers) => state.LeagueReducer);
  const [adminLeagues, setAdminLeagues] = useState([] as ILeague[]);
  const [userLeagues, setUserLeagues] = useState([] as ILeague[]);
  const [selectedLeague, setSelectedLeague] = useState({} as ILeague);
  const [isManageOpen, openManage] = useState(false);
  const {
    tournament: { tournament },
  } = props;

  const [, setAllTournament] = useState([] as ILeague[]);
  const tabCustomStyles = {
    selected: classes.tabSelected,
  };
  const [value, setValue] = useState(0);
  const handleChange = (event: object, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  // constructor and destructor
  useEffect(() => {
    const getUserLeagues = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_USER_LEAGUES });
    };
    getUserLeagues();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  // store watcher
  useEffect(() => {
    const updateUserLeagues = async () => {
      const leagues = await store.user_leagues;

      const sortedAdminLeagues = reverse(
        sortBy(
          get(leagues, "result.Items[0].adminLeagues"),
          (l) => l.created || ""
        )
      );

      const sortedUserLeagues = reverse(
        sortBy(
          get(leagues, "result.Items[0].userLeagues"),
          (l) => l.created || ""
        )
      );
      setAdminLeagues(sortedAdminLeagues);
      setUserLeagues(sortedUserLeagues);
    };
    updateUserLeagues();
  }, [store.user_leagues]);

  const navigateToLeague = (leagueName: string, tournament: string) => {
    const url = URL.LEAGUES.SURVIVOR.replace(":game", tournament).replace(
      ":league",
      leagueName
    );
    history.push(url);
  };

  const navigateToLeaguePlayer = (leagueName: string, tournament: string) => {
    const url = URL.LEAGUES.SURVIVOR_PLAYER.replace(
      ":game",
      tournament
    ).replace(":league", leagueName);
    history.push(url);
  };

  useEffect(() => {
    const getAllTournament = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_TOURNAMENT });
    };
    getAllTournament();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch]);

  // store watcher
  useEffect(() => {
    const updateAllTournament = async () => {
      const tournaments = await store.all_tournament;
      const sortedAllTournamnet = reverse(
        sortBy(get(tournaments, "result.Items"), (l) => l.created || "")
      );
      setAllTournament(sortedAllTournamnet);
    };
    updateAllTournament();
  }, [store.all_tournament]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppBar
            position="static"
            color="primary"
            classes={{
              colorPrimary: classes.tabTitle,
            }}
          >
            <Typography className={classes.leagueTitle} variant="h5" noWrap>
              {`${tournament} Tournament`}
            </Typography>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
              centered
              key={tournament}
            >
              <Tab
                label="Leagues you own"
                icon={<PersonIcon />}
                {...a11yProps(0)}
                classes={tabCustomStyles}
              />
              <Tab
                label="Joined League"
                icon={<GroupIcon />}
                {...a11yProps(1)}
                classes={tabCustomStyles}
              />
            </Tabs>
          </AppBar>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            style={{ height: "320px", backgroundColor: "#f5f5f5" }}
          >
            <TabPanel value={value} index={0}>
              <>
                {!isEmpty(adminLeagues) ? (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {map(adminLeagues, (league, key) => (
                        <>
                          {tournament === league.tournament ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={league.leagueName}
                              >
                                <Card className={classes.cardroot}>
                                  <CardContent className={classes.cardsize}>
                                    <Typography variant="h5" component="h2">
                                      League - {league.leagueName}
                                    </Typography>

                                    <Typography
                                      className={classes.title}
                                      color="textSecondary"
                                      gutterBottom
                                    >
                                      Description - {league.description}
                                    </Typography>
                                    <Typography
                                      className={classes.pos}
                                      color="textSecondary"
                                    >
                                      Password - {league.password}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="p"
                                    >
                                      {league.userId}
                                    </Typography>
                                  </CardContent>
                                  <Divider />
                                  <CardActions
                                    className={classes.buttonJustify}
                                  >
                                    {league.type === "team" ? (
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          navigateToLeague(
                                            league.leagueName,
                                            league.tournament
                                          )
                                        }
                                      >
                                        Play Team
                                      </Button>
                                    ) : league.type === "player" ? (
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          navigateToLeaguePlayer(
                                            league.leagueName,
                                            league.tournament
                                          )
                                        }
                                      >
                                        Play Player
                                      </Button>
                                    ) : league.type === "both" ? (
                                      <>
                                        {" "}
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          color="primary"
                                          onClick={() =>
                                            navigateToLeague(
                                              league.leagueName,
                                              league.tournament
                                            )
                                          }
                                        >
                                          Play Team
                                        </Button>
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          color="primary"
                                          onClick={() =>
                                            navigateToLeaguePlayer(
                                              league.leagueName,
                                              league.tournament
                                            )
                                          }
                                        >
                                          Play Player
                                        </Button>
                                      </>
                                    ) : (
                                      <>{""}</>
                                    )}
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      color="default"
                                      onClick={() => {
                                        setSelectedLeague(league);
                                        dispatch({
                                          type:
                                            LEAGUE_ACTIONS.GET_LEAGUE_MEMBERS,
                                          tournament,
                                          leagueName: league.leagueName,
                                        });
                                        openManage(true);
                                      }}
                                    >
                                      Invite
                                    </Button>
                                  </CardActions>
                                </Card>
                              </Grid>
                            </>
                          ) : null}
                        </>
                      ))}
                    </Grid>
                  </Grid>
                ) :
                  <CircularProgress color="primary" />
                }
              </>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <>
                {!isEmpty(userLeagues) ? (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {map(userLeagues, (league) => (
                        <>
                          {tournament === league.tournament ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={league.leagueName}
                              >
                                <Card className={classes.cardroot}>
                                  <CardContent>
                                    <Typography variant="h5" component="h2">
                                      League - {league.leagueName}
                                    </Typography>
                                    <Typography
                                      className={classes.title}
                                      color="textSecondary"
                                      gutterBottom
                                    >
                                      Description - {league.description}
                                    </Typography>
                                  </CardContent>
                                  <Divider />
                                  <CardActions
                                    className={classes.buttonJustify}
                                  >
                                    {league.type === "team" ? (
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          navigateToLeague(
                                            league.leagueName,
                                            league.tournament
                                          )
                                        }
                                      >
                                        Play Team
                                      </Button>
                                    ) : league.type === "player" ? (
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          navigateToLeaguePlayer(
                                            league.leagueName,
                                            league.tournament
                                          )
                                        }
                                      >
                                        Play Player
                                      </Button>
                                    ) : league.type === "both" ? (
                                      <>
                                        {" "}
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          color="primary"
                                          onClick={() =>
                                            navigateToLeague(
                                              league.leagueName,
                                              league.tournament
                                            )
                                          }
                                        >
                                          Play Team
                                        </Button>
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          color="primary"
                                          onClick={() =>
                                            navigateToLeaguePlayer(
                                              league.leagueName,
                                              league.tournament
                                            )
                                          }
                                        >
                                          Play Player
                                        </Button>{" "}
                                      </>
                                    ) : null}
                                  </CardActions>
                                </Card>
                              </Grid>
                            </>
                          ) : null}
                        </>
                      ))}
                    </Grid>
                  </Grid>
                ) :
                  <CircularProgress color="primary" />
                }
              </>
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>

      <ManageLeagueDialog
        open={isManageOpen}
        league={selectedLeague}
        handleClose={() => openManage(false)}
      />
    </React.Fragment>
  );
}
