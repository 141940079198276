import React, { useEffect, useState } from "react";
import { URL } from "../../Routes";
import ScoreBoard from "./modules/components/ScoreBoard";
import ScoreBoardPlayer from "./modules/components/ScoreBoardPlayer";
import { Container, Grid } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import DashboardAPI, {
  ITournament,
  ITournamentPlayerList,
  IGameSchedule,
} from "../../api/DashboardAPI";
import Intro from "./modules/components/Intro";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { LEAGUE_ACTIONS } from "../../reducers/LeagueReducer";
import { reducers } from "../../reducers";
import { isEmpty, map, sortBy, reverse, get } from "lodash-es";

interface IDashboardProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  admin: boolean;
  history: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tournamentScores: {
    padding: 0,
  },
  leaguesSection: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  tabTitle: {
    backgroundColor: "#ffffff",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "90.00%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionStyle: {
    padding: "1px 0px",
  },
  tabSelected: {
    color: theme.palette.primary.dark + " !important",
  },
}));

export default function Dashboard(props: IDashboardProps) {
  const currentUrl = window.location.pathname;
  const currentUrl22 = currentUrl.split("/");
  const tournament = currentUrl22[3];
  const tournamentType = currentUrl22[4];
  const CURRENT_TOURNAMENT = tournament;
  // if()
  if (!props.isAuthenticated) props.history.push(URL.HOME);
  if (props.admin)
    props.history.push(
      URL.LEAGUES.SCHEDULE_EDITOR.replace(":game", CURRENT_TOURNAMENT)
    );
  const classes = useStyles();
  const tabCustomStyles = {
    selected: classes.tabSelected,
  };

  const [scores, setTournamentScores] = useState({} as ITournament[]);
  const [scoresPlayer, setTournamentScoresPlayer] = useState({} as ITournamentPlayerList[]);
  const [scoresPlayerTwo, setTournamentScoresPlayerTwo] = useState({} as ITournamentPlayerList[]);
  const [value, setValue] = React.useState(tournamentType === "Player" ? 1 : 0);
  const [leagueType] = useState('player');
  const [leagueTypeTwo] = useState('both');
  const dispatch = useDispatch();
  const store: any = useSelector((state: reducers) => state.LeagueReducer);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    updateMatchDetails();
  };

  // constructor and destructor
  useEffect(() => {
    function getScores() {
      const scorePromises = map(['kp'], (tournament1) =>
        DashboardAPI.getScores(tournament)
      );

      Promise.all(scorePromises).then((results) => {
        const tournamentScores = map(results, "result.Item");
        setTournamentScores(tournamentScores);
      });
    }


    function getScoresPlayer() {
      const scorePromises = map(['kp'], (tournament1) =>
        DashboardAPI.getUserLeagueByLeageType(tournament, leagueType)
      );

      Promise.all(scorePromises).then((results) => {
        const tournamentScores = map(results, "result.Item");
        setTournamentScoresPlayer(tournamentScores);
      });
    }
    // getScoresPlayer();

    function getScoresPlayerTwo() {
      const scorePromises = map(['kp'], (tournament1) =>
        DashboardAPI.getUserLeagueByLeageTypeTwo(tournament, leagueTypeTwo)
      );
      console.log(scorePromises)
      Promise.all(scorePromises).then((results) => {
        const tournamentScores = map(results, "result.Item");
        console.log(tournamentScores);

        setTournamentScoresPlayerTwo(tournamentScores);
      });
    }
    getScores();
    getScoresPlayer();
    getScoresPlayerTwo();
  }, [tournament, leagueType, leagueTypeTwo]);

  const [matchSchedule, setMatchSchedule] = useState<IGameSchedule[]>([]);
  // console.log(matchSchedule);
  const updateMatchDetails = async () => {
    const matches = await store.schedule;
    const sortedMatchSchedule = reverse(sortBy(get(matches, "result", {})));
    setMatchSchedule(sortedMatchSchedule);
  };

  useEffect(() => {
    const getSchedule = async () => {
      dispatch({
        type: LEAGUE_ACTIONS.GET_SCHEDULE,
        tournament,
      });
    };
    getSchedule();
  }, [dispatch, tournament]);



  return (
    <Container maxWidth="lg">
      <Intro
        title={tournament}
        description=""
        image="https://source.unsplash.com/hY3sn--SgwM"
        imgText="my leagues"
        linkText=""
      />
      <AppBar
        position="static"
        color="primary"
        classes={{
          colorPrimary: classes.tabTitle,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          centered
        >
          {tournamentType === "Both" &&

            <Tab
              label="Team Leagues"
              icon={<GroupIcon />}
              {...a11yProps(0)}
              classes={tabCustomStyles}
            />

          }
          {tournamentType === "Both" &&

            <Tab
              label="Player Leagues"
              icon={<PersonIcon />}
              {...a11yProps(1)}
              classes={tabCustomStyles}
            />
          }


          {tournamentType === "Team" &&

            <Tab
              disabled
              label="Team Leagues"
              icon={<GroupIcon />}
              {...a11yProps(0)}
              classes={tabCustomStyles}
            />
          }
          {tournamentType === "Player" &&

            <Tab
              disabled
              label="Player Leagues"
              icon={<PersonIcon />}
              {...a11yProps(1)}
              classes={tabCustomStyles}
            />
          }

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {!isEmpty(scores) ? (
          map(scores, (tScore) => (
            <Grid
              key={tScore.tournament}
              container
              spacing={3}
              justify="center"
            >
              {map(tScore.leagues, (leagueScore) => (
                <ScoreBoard key={leagueScore.leagueName} score={leagueScore} />
              ))}
            </Grid>
          ))
        ) : (
          <Grid item xs={12} style={{ paddingTop: 50 }}>
            {/* Lodding... */}
            <CircularProgress color="primary" />
          </Grid>
        )}
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid container spacing={3} justify="center">
          {!isEmpty(scoresPlayer) ? (
            map(scoresPlayer, (tPlayerScore) => (
              <Grid
                key={tPlayerScore.tournament}
                container
                spacing={3}
                justify="center"
              >
                {map(tPlayerScore.leagues, (leaguePlayerScore) => (
                  <ScoreBoardPlayer key={leaguePlayerScore.leagueName} score={leaguePlayerScore} matchSchedule={matchSchedule} />
                ))}
              </Grid>
            ))
          ) : (
            <Grid item xs={12} style={{ paddingTop: 50 }}>
              {/* Lodding... */}
              <CircularProgress color="primary" />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3} justify="center">
          {map(scoresPlayerTwo, (tScore) => (
            <Grid
              key={tScore.tournament}
              container
              spacing={3}
              justify="center"
            >
              {map(tScore.leagues, (leagueScore) => (
                <ScoreBoardPlayer key={leagueScore.leagueName} score={leagueScore} matchSchedule={matchSchedule} />
              ))}
            </Grid>
          ))}
        </Grid>
      </TabPanel>
    </Container>
  );
}
