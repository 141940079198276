import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { URL } from "../../Routes";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Grid,
  Link,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@material-ui/core";
import DashboardAPI, {
  ITournamentScore,
  ITournamentStats,
} from "../../api/DashboardAPI";

import Intro from "./modules/components/Intro";
import TotalScoreBoard from "./modules/components/TotalScoreBoard";
import SwipeableViews from "react-swipeable-views";
import MatchStats from "./modules/components/MatchStats";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useDispatch } from "react-redux";
import { LEAGUE_ACTIONS } from "../../reducers/LeagueReducer";
import { findIndex } from "lodash-es";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Paper } from "@material-ui/core";
// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from "@material-ui/core/styles";
// import { Grid} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
// import JumboButton from "./modules/components/JumboButton";
import Box from "@material-ui/core/Box";
import { map } from "lodash-es";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import LeagueAPI from "../../api/LeagueAPI";
import TournamnetSummaryTop60Players from "./TournamnetSummaryTop60Players";
////////

interface IInsightProps {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  admin: boolean;
  match: {
    params: { [key: string]: string };
  };
  history: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
  tabTitle: {
    backgroundColor: "#ffffff",
  },
  tabSelected: {
    color: theme.palette.primary.dark + " !important",
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  scoreInsights: {
    marginBottom: theme.spacing(2),
  },
  tournamentScores: {
    padding: 0,
  },
  leaguesSection: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  slide: {
    padding: 15,
    minHeight: 100,
    color: "#000",
  },
  playerChip: {
    color: "#0000009a",
    marginRight: "100px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  playerPaper: {
    paddingTop: "10px",
    paddingBottom: "20px",
  },
  prictplayerTitle: {
    fontSize: "20px",
    marginBottom: "10px",
  },
  playerPaper2: {
    minWidth: "200px",
    margin: "5px",
    display: "block",
  },
  CardSet: {
    padding: "5px"
  },
  PointColor: {
    color: "#FFF",
  },
  topPoint: {
    display: 'flex',
    justifyContent: 'around'
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const listNotFound = [
  {
    playerName: "Not Found",
    team: "Not Found",
    count: 0,
  },
  {
    playerName: "Not Found",
    team: "Not Found",
    count: 0,
  },
  {
    playerName: "Not Found",
    team: "Not Found",
    count: 0,
  },
  {
    playerName: "Not Found",
    team: "Not Found",
    count: 0,
  },
  {
    playerName: "Not Found",
    team: "Not Found",
    count: 0,
  },
];
export default function TournamentSummary(props: IInsightProps) {
  if (!props.isAuthenticated) props.history.push(URL.HOME);
  // const classes = useStyles();
  const currentUrl = window.location.pathname;
  const currentUrl22 = currentUrl.split("/");
  const tournament = currentUrl22[2];
  const tournamentType = currentUrl22[3];
  console.log(tournamentType);

  const [scores, setTournamentScores] = useState({} as ITournamentScore);
  const [tournamentStats, setTournamentStats] = useState(
    {} as ITournamentStats
  );
  const [matchIndex, setMatchIndex] = useState(0);
  const dispatch = useDispatch();
  const [top5player, setTop5PridictedPlayer] = useState([[]]);
  const [top60player, setTop60Player] = useState([]);

  /////////////////////
  const classes = useStyles();
  const tabCustomStyles = {
    selected: classes.tabSelected,
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(tournamentType === "Player" ? 1 : 0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  //////////////////////////////

  useEffect(() => {
    const getAllLeague = async () => {
      dispatch({ type: LEAGUE_ACTIONS.GET_ALL_TOURNAMENT, tournament });
    };
    getAllLeague();
    return function cleanup() {
      dispatch({ type: LEAGUE_ACTIONS.RESET });
    };
  }, [dispatch, tournament]);

  useEffect(() => {
    function getScores() {
      DashboardAPI.getTotalScores(tournament).then((response) => {
        setTournamentScores(response.result.Item);
      });
    }
    function getStats() {
      DashboardAPI.getStats(tournament).then((response) => {
        const tournamentStats = response.result.Item;
        const matchIdx = findIndex(
          tournamentStats.stats,
          (stat) => stat.completed === false
        );
        setMatchIndex(matchIdx < 0 ? 0 : matchIdx);
        console.log(tournamentStats);

        setTournamentStats(tournamentStats);
      });
    }
    function getTop5Players() {
      LeagueAPI.getTop5PridictedPlayersCount(tournament).then((response) => {
        if (response.length >= 1) {
          setTop5PridictedPlayer(response);
        } else if (response.length === 0) {
          // setTop5PridictedPlayer(listNotFound);
        }
      });
    }
    function getTop60Players() {
      LeagueAPI.getTop60HighestPlayersScores(tournament).then((response) => {
        console.log(response);

        if (response.length >= 1) {
          setTop60Player(response);
        }
      });
    }

    getScores();
    getTop5Players();
    getTop60Players();
    getStats();
    return function cleanup() { };
  }, [tournament]);

  const navigateToJoin = () => {
    const url = URL.LEAGUES.JOIN;
    props.history.push(url);
  };
  const navigateToHome = () => {
    const url = URL.LEAGUES.HOME;
    props.history.push(url);
  };

  return (
    <Container maxWidth="lg" className={classes.mainGrid}>
      <Intro
        title={
          <Link
            color="inherit"
            variant="h6"
            underline="none"
            component={RouterLink}
            to={URL.LEAGUES.MANAGE}
          >
            {`${tournament} (type - ${tournamentType})`}
          </Link>
        }
        description="Click here to Manage your Leagues"
        image="https://source.unsplash.com/hY3sn--SgwM"
        imgText="my leagues"
        linkText=""
      />

      <Grid item xs={12} spacing={4} style={{ marginBottom: 10 }}>
        <Paper className={classes.leaguesSection}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                onClick={() => navigateToHome()}
              >
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                onClick={() => navigateToJoin()}
              >
                Join Leagues
              </Link>
              <Link
                underline="hover"
                color="primary"
                href="!#"
                aria-current="page"
              >
                Tournament Summary
              </Link>
            </Breadcrumbs>
          </div>
        </Paper>
      </Grid>

      <div className={classes.root}>
        <AppBar
          position="static"
          color="primary"
          classes={{
            colorPrimary: classes.tabTitle,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
            centered
          >
            {tournamentType === "Both" && (
              <Tab
                label="Team Leagues"
                icon={<GroupIcon />}
                {...a11yProps(0)}
                classes={tabCustomStyles}
              />
            )}
            {tournamentType === "Both" && (
              <Tab
                label="Player Leagues"
                icon={<PersonIcon />}
                {...a11yProps(1)}
                classes={tabCustomStyles}
              />
            )}
            {tournamentType === "Team" && (
              <Tab
                disabled
                label="Team Leagues"
                icon={<GroupIcon />}
                {...a11yProps(0)}
                classes={tabCustomStyles}
              />
            )}
            {tournamentType === "Player" && (
              <Tab
                disabled
                label="Player Leagues"
                icon={<PersonIcon />}
                {...a11yProps(1)}
                classes={tabCustomStyles}
              />
            )}
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Card elevation={3} className={classes.scoreInsights}>
              <CardHeader title="Match Statistics" />
              <Divider />
              <CardContent>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={1}>
                    <ChevronLeftIcon
                      onClick={() =>
                        matchIndex > 0 && setMatchIndex(matchIndex - 1)
                      }
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <SwipeableViews enableMouseEvents index={matchIndex}>
                      {map(tournamentStats.stats, (stats, idx) => (
                        <MatchStats
                          stats={stats}
                          idx={idx}
                          key={`${stats.left}vs${stats.right}${idx}`}
                        />
                      ))}
                    </SwipeableViews>
                  </Grid>
                  <Grid item xs={1}>
                    <ChevronRightIcon
                      onClick={() =>
                        matchIndex < tournamentStats.stats.length - 1 && matchIndex < top5player.length - 1 &&
                        setMatchIndex(matchIndex + 1)
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card elevation={3} className={classes.scoreInsights}>
              <CardHeader title="Tournament Standings" />
              <Divider />
              <CardContent>
                <Grid container spacing={3} justify="center">
                  <TotalScoreBoard
                    score={scores}
                    survivorSelector="tournamentSurvivorRank"
                    confidenceSelector="tournamentConfidenceRank"
                  />
                </Grid>
              </CardContent>
            </Card>
            {/* {Completed} */}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Card elevation={3} className={classes.scoreInsights}>
              <CardHeader title="Most Selected Players" />
              <Divider />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <div>
                  Match #{matchIndex+1}<br/>
                    {tournamentStats.stats ?
                      `${tournamentStats?.stats[matchIndex]?.left} VS ${tournamentStats?.stats[matchIndex]?.right}`
                      :
                      null

                    }

                  </div>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >

                  <ChevronLeftIcon
                    onClick={() =>
                      matchIndex > 0 && setMatchIndex(matchIndex - 1)
                    }
                  />
                  <Grid
                    item
                    style={{
                      display: "inline-flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {top5player[matchIndex] !== undefined ?
                      top5player[matchIndex]?.length >= 1 ?
                        top5player[matchIndex].map((ele: any, i) => {
                          return (
                            <React.Fragment>
                              <div className={classes.CardSet} key={i}>
                                <Chip
                                  avatar={
                                    <Avatar className={classes.PointColor}>
                                      #{i + 1}
                                    </Avatar>
                                  }
                                  label={
                                    <span>
                                      <h4>
                                        {ele.playerName} - {ele.count}
                                      </h4>
                                    </span>
                                  }
                                  variant="outlined"
                                  color="primary"
                                />
                              </div>
                            </React.Fragment>
                          );
                        }) :

                        listNotFound.map((ele: any, i) => {
                          return (
                            <React.Fragment>
                              <div className={classes.CardSet} key={i}>
                                <Chip
                                  avatar={
                                    <Avatar className={classes.PointColor}>
                                      #{i + 1}
                                    </Avatar>
                                  }
                                  label={
                                    <span>
                                      <h4>
                                        {ele.playerName} - {ele.count}
                                      </h4>
                                    </span>
                                  }
                                  variant="outlined"
                                  color="primary"
                                />
                              </div>
                            </React.Fragment>
                          );
                        })
                      : "not found"
                    }
                  </Grid>
                  <ChevronRightIcon
                    onClick={() =>
                      matchIndex < tournamentStats.stats.length - 1 && matchIndex < top5player.length - 1 &&
                      setMatchIndex(matchIndex + 1)
                    }
                  />
                </Grid>
              </CardContent>
            </Card>
            {/* Coming Soon... */}
            <br />
            <Card elevation={3}>
              <CardHeader title="Tournament Standings" />
              <Divider />
              <CardContent>
                <Grid container spacing={3} justify="center">
                  <TournamnetSummaryTop60Players
                    item
                    data={top60player}
                    tournament={tournament}
                  />
                </Grid>
              </CardContent>
            </Card>
          </TabPanel>
        </SwipeableViews>
      </div>
    </Container>
  );
}
